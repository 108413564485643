import React, { createContext, useState, useEffect } from 'react';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState({ username: '', profileImage: '' });

  useEffect(() => {
    const username = sessionStorage.getItem('username');
    const profileImage = sessionStorage.getItem('profilePicture');
    const id = sessionStorage.getItem('id');
    const upi = sessionStorage.getItem('upi');
    const email = sessionStorage.getItem('email');
    const role = sessionStorage.getItem('role');
    setUser({
      username: username || 'Guest',
      profileImage: profileImage || '',
      id: id || null,
      upi: upi || null,
      email: email || null,
      role: role || null
    });
  }, []);

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
};
