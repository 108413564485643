import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Modal.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode';
import { UserContext } from '../context/UserContext';

const SignInModal = ({ closeModal }) => {
  const { setUser } = useContext(UserContext);
  const navigate = useNavigate();
  const [isSmallMediumScreen, setIsSmallMediumScreen] = useState(false);

  useEffect(() => {
    const checkScreenSize = () => {
      setIsSmallMediumScreen(window.innerWidth < 1024);
    };

    checkScreenSize();
    window.addEventListener('resize', checkScreenSize);

    return () => window.removeEventListener('resize', checkScreenSize);
  }, []);

  const handleSignInSuccess = async (credentialResponse) => {
    try {
      const token = credentialResponse.credential;

      const decodedToken = jwtDecode(token);
      const { name, picture } = decodedToken;

      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/auth-receiver`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: new URLSearchParams({ credential: token }),
        }
      );

      if (response.ok) {
        const userData = await response.json();

        sessionStorage.setItem('authToken', token);
        sessionStorage.setItem('username', name);
        sessionStorage.setItem('profilePicture', picture);
        sessionStorage.setItem('id', userData.user_data.id);
        sessionStorage.setItem('upi', userData.user_data.upi);
        sessionStorage.setItem('email', userData.user_data.email);
        sessionStorage.setItem('role', userData.user_data.role);

        setUser({
          username: name,
          profileImage: picture,
          id: userData.user_data.id,
          upi: userData.user_data.upi,
          email: userData.user_data.email,
          role: userData.user_data.role,
        });

        const role = userData.user_data.role;
        if (role === 'student') {
          navigate('/learning');
        } else if (role === 'instructor' || role === 'admin') {
          navigate('/manage');
        }
      } else {
        console.error('Failed to authenticate user:', response.statusText);
      }
    } catch (error) {
      console.error('Error during sign-in:', error);
    }
  };

  const handleSignInError = () => {
    console.log('Login Failed');
  };

  return (
    <div className="modal-background" onClick={closeModal}>
      <div className="modal-container" onClick={(e) => e.stopPropagation()}>
        <button className="close-button" onClick={closeModal}>
          <i className="fa-solid fa-x"></i>
        </button>

        <h2 className="modal-title">Sign In</h2>
        <p className="modal-text">Please sign in with your university email.</p>

        <div className="google-signin">
          <GoogleLogin
            theme="outline"
            shape="pill"
            text="continue_with"
            locale="en"
            width="300px"
            type={isSmallMediumScreen ? 'icon' : 'standard'}
            onSuccess={handleSignInSuccess}
            onError={handleSignInError}
          />
        </div>

        <p className="modal-contact-text">
          Having trouble signing in?{' '}
          <b>
            <a href="mailto:coldbrew399@gmail.com">Contact us</a>
          </b>
        </p>
      </div>
    </div>
  );
};

export default SignInModal;
