import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './NavBar.css';

const StudentNavBar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <nav className="student-navbar">
      <div className="navbar-header">
        <i className="fa-solid fa-bars menu-icon" onClick={toggleMenu}></i>
      </div>
      <ul className={`student-navbar-list ${isMenuOpen ? 'open' : ''}`}>
        <li>
          <NavLink
            to="/learning"
            className={({ isActive }) => (isActive ? 'active' : '')}
          >
            Learning
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/achievements"
            className={({ isActive }) => (isActive ? 'active' : '')}
          >
            Achievements
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/feedback"
            className={({ isActive }) => (isActive ? 'active' : '')}
          >
            Feedback
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/help"
            className={({ isActive }) => (isActive ? 'active' : '')}
          >
            Help
          </NavLink>
        </li>
      </ul>
    </nav>
  );
};

export default StudentNavBar;
