import React, { useState, useEffect } from 'react';
import { Accordion, Spinner } from 'react-bootstrap';
import './accordion.css';


const AllCoursesList = () => {
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/curriculum/courses`
        );
        if (response.ok) {
          const data = await response.json();
          setCourses(data.courses);
        } else {
          console.error('Error fetching courses');
        }
      } catch (error) {
        console.error('Error:', error);
        alert('Error fetching courses');
      } finally {
        setLoading(false);
      }
    };

    fetchCourses();
  }, []);

  if (loading) {
    return (
      <div style={{ textAlign: 'center' }}>
        <Spinner animation="border" variant="secondary" />
      </div>
    );
  }

  return (
    <div className="all-courses-list" style={{ paddingBottom: '20px' }}>
      <Accordion className="mb-3 mt-3">
        {courses.map((course) => (
          <Accordion.Item key={course.id} eventKey={course.id}>
            <Accordion.Header>
              <div style={{ flex: 1 }}>
                <h5>
                  {course.course_name} {course.course_code}:{' '}
                  {course.description}
                </h5>
                <p>
                  {course.semester === 3
                    ? 'Summer School'
                    : `Semester ${course.semester}`}{' '}
                  {course.year}
                </p>
              </div>
            </Accordion.Header>
            <Accordion.Body>
              <p>
                <b>{course.instructor_count}</b>{' '}
                {course.instructor_count > 1 ? 'instructors' : 'instructor'}
              </p>
              <p>
                <b>{course.student_count}</b>{' '}
                {course.student_count > 1 ? 'students' : 'student'}
              </p>
              
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
    </div>
  );
};

export default AllCoursesList;
