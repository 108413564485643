import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';

Chart.register(...registerables);

const LineGraph = ({ labels, dataPoints, chartLabel, chartTitle, yValue }) => {
  let maxY = 0;
  let maxTicksLimit = 0;
  let chartHeight = 0;
  if (yValue === 'logins') {
    maxY = Math.max(...dataPoints) + 1;
    maxTicksLimit = 15;
    chartHeight = '400px';
  } else if (yValue === 'attempts') {
    maxY = Math.max(...dataPoints) + 0.2;
    maxTicksLimit = 10;
    chartHeight = '300px';
  }

  const chartData = {
    labels: labels,
    datasets: [
      {
        label: chartLabel,
        backgroundColor: 'rgba(194, 116, 161, 0.5)',
        borderColor: 'rgb(194, 116, 161)',
        data: dataPoints,
        pointRadius: 0,
        lineTension: 0.2,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
      },
      title: {
        display: true,
        text: chartTitle,
        font: {
          size: 20,
        },
      },
      tooltip: {
        enabled: true,
        mode: 'nearest',
        intersect: false,
        callbacks: {
          label: function (context) {
            return `${chartLabel}: ${context.parsed.y}`;
          },
        },
      },
      zoom: {
        pan: {
          enabled: true,
          mode: 'xy',
        },
        zoom: {
          wheel: {
            enabled: true,
          },
          pinch: {
            enabled: true,
          },
          mode: 'xy',
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        max: maxY,
      },
      x: {
        display: true,
        ticks: {
          autoSkip: true,
          maxTicksLimit: maxTicksLimit,
        },
      },
    },
  };

  const containerStyle = {
    height: chartHeight,
    width: '100%',
    paddingBottom: yValue === 'logins' ? '20px' : '0',
  };

  return (
    <div style={containerStyle}>
      <Line data={chartData} options={options} />
    </div>
  );
};

export default LineGraph;
