const generatePdf = async (score, noQ, username, module, completedDate, course_name, course_code, description, fileName) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/curriculum/generate-pdf`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/pdf',
            },
            body: JSON.stringify({
                'username': username,
                'score': score,
                'noQ': noQ,
                'module': module,
                'course-name': course_name,
                'course-code': course_code,
                'course-description': description,
                'completedDate': completedDate
            })
        }).then((response) => response.blob())
            .then((blob) => {
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName || 'certificate.pdf');
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            })
    } catch (error) {
        console.error('Error generating pdf: ', error);
    }
};


export default generatePdf;