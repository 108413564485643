import React, { useState, useEffect, useRef, useContext } from 'react';
import { Spinner, Accordion, Form, ListGroup, Row, Col } from 'react-bootstrap';
import { UserContext } from '../context/UserContext';
import {
  onSendMessageTopic,
  connectToSM,
  onSendMessage,
  toggleMute,
  toggleIOA,
  generateQuiz,
  closeScene,
  deactivateKeyboardInput,
  activateKeyboardInput,
  updateSMData,
  generateIOA,
  toggleCamera
} from './SoulMachines';
import './accordion.css';
import QuizPassModal from './QuizPassModal';
import QuizFailModal from './QuizFailModal';
import {
  FaMicrophone,
  FaMicrophoneSlash,
  FaVideo,
  FaVideoSlash,
} from 'react-icons/fa';
import { useLocation } from 'react-router-dom';
import logo from './Sofia-logo-white.png';

const CourseContent = ({ selectedCourse }) => {
  const { user } = useContext(UserContext);
  const [contentLoading, setContentLoading] = useState(false);
  const [showButtons, setShowButtons] = useState(true);
  const [isMuted, setIsMuted] = useState(false);
  const [studentProgress, setStudentProgress] = useState({});
  const [lastActionTime, setLastActionTime] = useState(0);
  const [passingScore, setPassingScore] = useState(null);
  const [quizResult, setQuizResult] = useState(null);
  const [transcript, setTranscript] = useState(null);
  const [isQuizResultProcessed, setIsQuizResultProcessed] = useState(false);
  const [isPassModalOpen, setIsPassModalOpen] = useState(false);
  const [isFailModalOpen, setIsFailModalOpen] = useState(false);
  const [IOA, setIsIOA] = useState(false);
  const [showContent, setDisplayContent] = useState(false);
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [isCameraOn, setIsCameraOn] = useState(true);

  let chatbox;
  let scene;
  const videoRef = useRef(null);
  const chatBoxRef = useRef(null);

  const cameraStream = useRef(null);
  const username = user.name;
  const location = useLocation();

  const handleOpenPassModal = () => setIsPassModalOpen(true);
  const handleOpenFailModal = () => setIsFailModalOpen(true);
  const handleClosePassModal = () => setIsPassModalOpen(false);
  const handleCloseFailModal = () => setIsFailModalOpen(false);

  function handleQuizResult(score, moduleName, moduleId) {
    setIsQuizResultProcessed(false);
    setQuizResult({ score, moduleName, moduleId });
  }

  const handleTranscript = (transcript) => {
    setTranscript(transcript);
  };
  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     const speaking = Math.random() > 0.5;
  //     setIsSpeaking(speaking);
  //   }, 2000);



  //   return () => clearInterval(interval);
  // }, []);

  // useEffect(() => {
  //   const startDetectingAudio = async () => {
  //     try {
  //       const stream = videoRef.current.srcObject; // or similar, depending on how the avatar accesses it

  //     if (stream) {
  //       const audioContext = new (window.AudioContext || window.webkitAudioContext)();
  //       const analyser = audioContext.createAnalyser();
  //       const source = audioContext.createMediaStreamSource(stream);
  //       source.connect(analyser);

  //       // Now analyze the stream without requesting getUserMedia again
  //       const dataArray = new Uint8Array(analyser.fftSize);
  //       analyser.fftSize = 2048;

  //       const detectSpeaking = () => {
  //         analyser.getByteTimeDomainData(dataArray);
  //         const volume = dataArray.reduce((sum, value) => sum + Math.abs(value - 128), 0) / dataArray.length;
  //         setIsSpeaking(volume > 10); // Adjust threshold
  //         requestAnimationFrame(detectSpeaking);
  //       };

  //       detectSpeaking();
  //     }
  //     } catch (err) {
  //       console.error('Error accessing microphone:', err);
  //     }
  //   };

  //   startDetectingAudio();

  //   return () => {
  //     // Cleanup function
  //     setIsSpeaking(false);
  //   };
  // }, []);

  useEffect(() => {
    if (quizResult && passingScore !== null && !isQuizResultProcessed) {
      const { score, moduleName, moduleId } = quizResult;

      if (score >= passingScore) {
        handleOpenPassModal();

        setStudentProgress((prevState) => {
          const updatedProgress = { ...prevState };
          const moduleData = updatedProgress[moduleName];

          if (moduleData) {
            updatedProgress[moduleName].quiz_passed = true;

            const allTopicsComplete = moduleData.topics.every(
              (topic) => topic.is_complete
            );

            if (allTopicsComplete && !moduleData.is_complete) {
              updatedProgress[moduleName].is_complete = true;
            }
          }
          return updatedProgress;
        });
      } else {
        handleOpenFailModal();
      }
      setIsQuizResultProcessed(true);
    }
  }, [quizResult, isQuizResultProcessed]);

  useEffect(() => {
    const fetchStudentProgress = async () => {
      if (selectedCourse) {
        // setContentLoading(true);
        try {
          const progressResponse = await fetch(
            `${process.env.REACT_APP_BACKEND_URL}/curriculum/get-progress/${selectedCourse.id}/${user.id}`
          );

          if (progressResponse.ok) {
            const progressData = await progressResponse.json();
            setStudentProgress(progressData.data);
          } else {
            console.error('Error fetching student progress');
          }
        } catch (error) {
          console.error('Error:', error);
        } finally {
          // setContentLoading(false);
        }
      } else {
        setStudentProgress({});
      }
    };

    fetchStudentProgress();
    handleConnectClick();

    const handleBeforeUnload = (event) => {
      updateSMData(selectedCourse.id, user.id);
      const message =
        'You have unsaved changes. Are you sure you want to leave?';
      event.preventDefault();
      event.returnValue = message;
      return message;
    };

    const handleResize = () => {
      const elements = document.getElementsByClassName('course-content-list');
      if (window.innerWidth < 900) {
        for (let i = 0; i < elements.length; i++) {
          elements[i].style.display = 'none';
          setDisplayContent(false);
        }
      } else {
        for (let i = 0; i < elements.length; i++) {
          elements[i].style.display = 'block';
        }
      }
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    window.addEventListener('beforeunload', handleBeforeUnload);

    window.hasUnsavedChanges = true;

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('resize', handleResize);
      console.log('update');
    };
  }, [selectedCourse, user.id]);

  useEffect(() => {
    console.log('User is on route:', location.pathname);

    return () => {
      console.log('User is leaving route:', location.pathname);
      if (scene) {
        scene.close();
        scene = null;
      }
      localStorage.removeItem('sessionId');
      updateSMData(selectedCourse.id, user.id);
      closeScene();
    };
  }, [location]);

  const handleConnectClick = async () => {
    setContentLoading(true);
    console.log('iteration');
    await new Promise((resolve) => setTimeout(resolve, 10));
    console.log(videoRef.current);
    if (videoRef.current && chatBoxRef.current) {
      scene = await connectToSM(
        videoRef.current,
        chatBoxRef.current,
        username,
        selectedCourse.id,
        user.id
      );
      if (scene != null) {
        setShowButtons(false);
        setContentLoading(false);
        scene.conversation.onConversationStateUpdated.addListener((ConversationState) => {
          // console.log(ConversationState);
          if (ConversationState === "userSpeaking"){
            setIsSpeaking(true);
          }
          else{
            setIsSpeaking(false);
          }
        }
        );
        const loadingimg = document.getElementById('loadingimg');
        loadingimg.style.display = 'none';
      }
    } else {
      handleConnectClick();
    }
  };

  const handleSendMessageClick = () => {
    const userText = document.getElementById('userInputSM');
    if (userText) {
      onSendMessage(userText, selectedCourse.id, user.id);
    }
  };

  const handleSendMessageEnter = (event) => {
    const userText = document.getElementById('userInputSM');
    if (userText && event.key === 'Enter') {
      onSendMessage(userText, selectedCourse.id, user.id);
      event.preventDefault();
    }
  };

  const handleMuteClick = async () => {
    try {
      const muteStatus = await toggleMute();
      setIsMuted(muteStatus);
    } catch (error) {
      console.error('Error toggling mute:', error);
    }
  };

  const handleCameraToggle = async () => {
    try {
      const cameraStatus = await toggleCamera();
      setIsCameraOn(cameraStatus);
    } catch (error) {
      console.error('Error toggling camera:', error);
    }
  };

  const handleShowContent = () => {
    setDisplayContent(!showContent);
    const elements = document.getElementsByClassName('course-content-list');
    for (let i = 0; i < elements.length; i++) {
      elements[i].style.display = showContent ? 'none' : 'block';
    }
  };

  const handleIOAswitch = async () => {
    try {
      const IOAstatus = await toggleIOA();
      setIsIOA(IOAstatus);
      const userInputSM = document.getElementById('userInputSM');
      const sendButton = document.getElementById('send_message_soul_machine');
      if (!IOAstatus) {
        activateKeyboardInput(userInputSM, sendButton);
      } else {
        deactivateKeyboardInput(userInputSM, sendButton);
      }
      handleConnectClick();
    } catch (error) {
      console.error('Error toggling IOA:', error);
    }
  };

  const handleCompletedClick = async (
    moduleName,
    topicId,
    subtopicId = null
  ) => {
    const currentTime = Date.now();
    if (currentTime - lastActionTime < 30000) {
      // 60,000 milliseconds = 1 minute
      console.warn(
        'You can only complete one topic or subtopic per one-half minute.'
      );
      return;
    }

    try {
      let apiUrl;
      if (subtopicId) {
        apiUrl = `${process.env.REACT_APP_BACKEND_URL}/curriculum/complete-topic/${subtopicId}/${user.id}`;
      } else {
        apiUrl = `${process.env.REACT_APP_BACKEND_URL}/curriculum/complete-topic/${topicId}/${user.id}`;
      }

      const moduleData = studentProgress[moduleName];
      const topicData = moduleData.topics.find(
        (topic) => topic.topic_id === topicId
      );

      if (subtopicId) {
        const subtopicData = topicData.subtopics.find(
          (subtopic) => subtopic.topic_id === subtopicId
        );
        if (!subtopicData) {
          console.error('Subtopic not found in progress data');
          return;
        }
        subtopicData.is_complete = true;

        const allSubtopicsComplete = topicData.subtopics.every(
          (subtopic) => subtopic.is_complete
        );
        if (allSubtopicsComplete) {
          topicData.is_complete = true;
        }
      } else {
        topicData.is_complete = true;
      }

      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        setStudentProgress({ ...studentProgress });
        setLastActionTime(currentTime);
      } else {
        console.error('Error updating topic completion');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  // if (contentLoading) {
  //   return (
  //     <div style={{ textAlign: 'center' }}>
  //       <Spinner animation="border" variant="secondary" />
  //     </div>
  //   );
  // }

  const indexStyle = { color: '#777777' };
  const iconStyle = {
    color: 'rgba(139, 0, 103, 0.4)',
    fontSize: '18px',
  };

  const completedIcon = (
    <i className="fa-solid fa-circle-check" style={iconStyle}></i>
  );
  const notCompletedIcon = (
    <i className="fa-regular fa-circle" style={iconStyle}></i>
  );

  return (
    <div className="course-content" style={{ display: 'flex' }}>
      <div className="course-content-list">
        <Accordion className="mt-2 pb-3">
          <Accordion.Item className="pt-3 ps-3 pb-1">
            <h5>
              {selectedCourse.course_name} {selectedCourse.course_code}:{' '}
              {selectedCourse.description}
            </h5>
          </Accordion.Item>
          {Object.keys(studentProgress).length === 0 ? (
            <Accordion.Item className="pt-3 ps-3 pb-1">
              <p>
                No content available for {selectedCourse.course_name}{' '}
                {selectedCourse.course_code}: {selectedCourse.description}
              </p>
            </Accordion.Item>
          ) : !showButtons ? (
            // Module
            Object.entries(studentProgress).map(
              ([module, moduleData], moduleIndex) => (
                <Accordion.Item
                  eventKey={`module-${moduleIndex}`}
                  key={moduleIndex}
                >
                  <Accordion.Header>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      {!IOA ? (
                        <div style={{ marginRight: '1em' }}>
                          {studentProgress[module]?.is_complete
                            ? completedIcon
                            : notCompletedIcon}
                        </div>
                      ) : (
                        ''
                      )}
                      <span style={indexStyle}>{moduleIndex + 1}</span>
                      <div style={{ marginLeft: '1em' }}>{module}</div>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <Accordion>
                      {/* Topic */}
                      {moduleData.topics.map((topic, topicIndex) => {
                        const subtopics = topic.subtopics;
                        // If topic has at least 1 subtopic, display as accordion
                        return subtopics.length > 0 ? (
                          <Accordion.Item
                            eventKey={`topic-${moduleIndex}-${topicIndex}`}
                            key={topicIndex}
                          >
                            <Accordion.Header>
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                {!IOA ? (
                                  <div style={{ marginRight: '1em' }}>
                                    {topic.is_complete
                                      ? completedIcon
                                      : notCompletedIcon}
                                  </div>
                                ) : (
                                  ''
                                )}
                                <span style={indexStyle}>
                                  {moduleIndex + 1}.{topicIndex + 1}
                                </span>
                                <div style={{ marginLeft: '1em' }}>
                                  {topic.topic_name}
                                </div>
                              </div>
                            </Accordion.Header>
                            <Accordion.Body>
                              <ListGroup as="ol">
                                {/* Subtopics displayed as ListGroup.Item (not expandable) */}
                                {subtopics.map((subtopic, subtopicIndex) => (
                                  <ListGroup.Item as="li" key={subtopicIndex}>
                                    <div
                                      style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                      }}
                                    >
                                      {!IOA ? (
                                        <div style={{ marginRight: '1em' }}>
                                          {subtopic.is_complete
                                            ? completedIcon
                                            : notCompletedIcon}
                                        </div>
                                      ) : (
                                        ''
                                      )}
                                      <span style={indexStyle}>
                                        {moduleIndex + 1}.{topicIndex + 1}.
                                        {subtopicIndex + 1}
                                      </span>
                                      <div style={{ marginLeft: '1em' }}>
                                        <span
                                          onClick={() => {
                                            if (IOA) {
                                              generateIOA(
                                                user.id,
                                                subtopic.topic_name
                                              );
                                              onSendMessageTopic(
                                                subtopic.topic_name,
                                                selectedCourse.id,
                                                user.id,
                                                'IOA'
                                              );
                                              console.log('IOA');
                                            } else {
                                              onSendMessageTopic(
                                                subtopic.topic_name,
                                                selectedCourse.id,
                                                user.id,
                                                'learning'
                                              );
                                              console.log('Learning');
                                              handleCompletedClick(
                                                module,
                                                topic.topic_id,
                                                subtopic.topic_id
                                              );
                                            }
                                          }}
                                          style={{
                                            cursor: 'pointer',
                                            color: 'inherit',
                                          }}
                                        >
                                          {subtopic.topic_name}
                                        </span>
                                      </div>
                                    </div>
                                  </ListGroup.Item>
                                ))}
                              </ListGroup>
                            </Accordion.Body>
                          </Accordion.Item>
                        ) : (
                          // If topic does not have a subtopic, display as ListGroup.Item (not expandable)
                          <Accordion.Item key={topicIndex}>
                            <ListGroup.Item
                              as="li"
                              style={{ padding: '0.75rem 1.25rem' }}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                {!IOA ? (
                                  <div style={{ marginRight: '1em' }}>
                                    {topic.is_complete
                                      ? completedIcon
                                      : notCompletedIcon}
                                  </div>
                                ) : (
                                  ''
                                )}
                                <span style={indexStyle}>
                                  {moduleIndex + 1}.{topicIndex + 1}
                                </span>
                                <div style={{ marginLeft: '1em' }}>
                                  <span
                                    onClick={() => {
                                      if (IOA) {
                                        generateIOA(user.id, topic.topic_name);
                                      } else {
                                        onSendMessageTopic(
                                          topic.topic_name,
                                          selectedCourse.id,
                                          user.id,
                                          'learning'
                                        );
                                        handleCompletedClick(
                                          module,
                                          topic.topic_id
                                        );
                                      }
                                    }}
                                    style={{
                                      cursor: 'pointer',
                                      color: 'inherit',
                                    }}
                                  >
                                    {topic.topic_name}
                                  </span>
                                </div>
                              </div>
                            </ListGroup.Item>
                          </Accordion.Item>
                        );
                      })}

                      {/* Oral Quiz */}
                      <Accordion.Item key={moduleIndex}>
                        <ListGroup.Item
                          as="li"
                          style={{ padding: '0.75rem 1.25rem' }}
                        >
                          {!IOA ? (
                            <div
                              style={{ display: 'flex', alignItems: 'center' }}
                            >
                              <div style={{ marginRight: '2.2em' }}>
                                {studentProgress[module].quiz_passed
                                  ? completedIcon
                                  : notCompletedIcon}
                              </div>
                              <div style={{ marginLeft: '1em' }}>
                                <span
                                  style={{
                                    cursor: 'pointer',
                                    color: '#2b2b2b',
                                    fontWeight: 'bold',
                                  }}
                                  onClick={async () => {
                                    try {
                                      let allTopics = Object.entries(
                                        studentProgress[module] || {}
                                      ).reduce((acc, [topic, subtopics]) => {
                                        acc.push(topic);

                                        if (Array.isArray(subtopics)) {
                                          subtopics.forEach((subtopic) => {
                                            acc.push(
                                              subtopic.topic_name || subtopic
                                            );
                                            if (
                                              Array.isArray(subtopic.subtopics)
                                            ) {
                                              subtopic.subtopics.forEach(
                                                (subSubtopic) => {
                                                  acc.push(
                                                    subSubtopic.topic_name ||
                                                      subSubtopic
                                                  );
                                                }
                                              );
                                            }
                                          });
                                        }

                                        return acc;
                                      }, []);

                                      const topics = allTopics
                                        .slice(1)
                                        .join(', ');
                                      console.log('topics: ' + topics);

                                      const response = await fetch(
                                        `${process.env.REACT_APP_BACKEND_URL}/curriculum/get-module-details/${studentProgress[module].module_id}`
                                      );
                                      const data = await response.json();
                                      setPassingScore(data.pass_rate);
                                      generateQuiz(
                                        user.id,
                                        { module }['module'],
                                        studentProgress[module].module_id,
                                        topics,
                                        data['no_of_questions'],
                                        data['pass_rate'],
                                        (score) =>
                                          handleQuizResult(
                                            score,
                                            module,
                                            studentProgress[module].module_id
                                          ),
                                        handleTranscript
                                      );
                                      const userInputSM =
                                        document.getElementById('userInputSM');
                                      const sendButton =
                                        document.getElementById(
                                          'send_message_soul_machine'
                                        );
                                      deactivateKeyboardInput(
                                        userInputSM,
                                        sendButton
                                      );
                                    } catch (error) {
                                      console.error('Error:', error);
                                    }
                                  }}
                                >
                                  Module {moduleIndex + 1}: Quiz
                                </span>
                              </div>
                            </div>
                          ) : (
                            <div
                              style={{ display: 'flex', alignItems: 'center' }}
                            >
                              <div style={{ marginRight: '2.2em' }}></div>
                              <div style={{ marginLeft: '1em' }}>
                                <span
                                  style={{
                                    cursor: 'pointer',
                                    color: '#2b2b2b',
                                    fontWeight: 'bold',
                                  }}
                                  onClick={async () => {
                                    try {
                                      let allTopics = Object.entries(
                                        studentProgress[module] || {}
                                      ).reduce((acc, [topic, subtopics]) => {
                                        acc.push(topic);

                                        if (Array.isArray(subtopics)) {
                                          subtopics.forEach((subtopic) => {
                                            acc.push(
                                              subtopic.topic_name || subtopic
                                            );
                                            if (
                                              Array.isArray(subtopic.subtopics)
                                            ) {
                                              subtopic.subtopics.forEach(
                                                (subSubtopic) => {
                                                  acc.push(
                                                    subSubtopic.topic_name ||
                                                      subSubtopic
                                                  );
                                                }
                                              );
                                            }
                                          });
                                        }

                                        return acc;
                                      }, []);

                                      const topics = allTopics
                                        .slice(1)
                                        .join(', ');
                                      console.log('topics: ' + topics);
                                      generateIOA(
                                        user.id,
                                        { module }['module'],
                                        studentProgress[module].module_id,
                                        topics
                                      );
                                    } catch (error) {
                                      console.error('Error:', error);
                                    }
                                  }}
                                >
                                  Module {moduleIndex + 1}: IOA
                                </span>
                              </div>
                            </div>
                          )}
                        </ListGroup.Item>
                      </Accordion.Item>
                    </Accordion>
                  </Accordion.Body>
                </Accordion.Item>
              )
            )
          ) : null}
        </Accordion>
      </div>

      {Object.keys(studentProgress).length > 0 && (
        <div
          className="myProgress-soulMachines-container"
          style={{ flex: '1', marginTop: '-30px' }}
        >
          {showButtons ? <></> : null}
          <div className="myProgress-layout">
            <Row className="mt-1 me-1 justify-content-end">
              <Col xs="auto">
                <Form.Check
                  type="switch"
                  className="myProgress-switch-button"
                  label={
                    IOA ? (
                      <>
                        <span className="long-label">
                          Interactive Oral Assessment
                        </span>
                        <span className="short-label">IOA</span>
                      </>
                    ) : (
                      'Learning and Quizzes'
                    )
                  }
                  checked={IOA}
                  onChange={() => handleIOAswitch()}
                />
              </Col>
            </Row>
            <button
              type="button"
              id="showContent"
              className="mt-1 me-1 justify-content-end"
              onClick={handleShowContent}
            >
              <i className="fa-solid fa-clipboard-list"></i>
              <span>Content</span>
            </button>
            <div
              className="video-container"
              style={{ margin: '0', position: 'relative' }}
            >
              <img
                src={logo}
                alt="Loading"
                class="breathing-img loading-screen"
                id="loadingimg"
                style={{
                  display: contentLoading ? 'block' : 'none',
                }}
              />

              <video ref={videoRef} id="sm-video" width="100%">
                Soul Machines Video
              </video>
              <button
                type="button"
                onClick={handleCameraToggle}
                className="icon-button"
                style={{
                  position: 'absolute',
                  bottom: '10px',
                  left: '60px',
                  background: 'none',
                  border: 'none',
                  cursor: 'pointer',
                  paddingBottom: '8px',
                }}
              >
                <i
                  className={`fa-solid ${
                    isCameraOn ? 'fa-video' : 'fa-video-slash'
                  }`}
                  style={{ fontSize: '22px' }}
                ></i>
              </button>
              <button
                type="button"
                onClick={handleMuteClick}
                style={{
                  position: 'absolute',
                  bottom: '10px',
                  left: '10px',
                  background: 'none',
                  border: 'none',
                  padding: '10px',
                  cursor: 'pointer',
                }}
              >
                {isMuted ? (
                  <FaMicrophoneSlash size={24} />
                ) : (
                  <FaMicrophone size={24} />
                )}
              </button>
              {isSpeaking &&  (
                <div className="wave-bars">
                  <div className="bar"></div>
                  <div className="bar"></div>
                  <div className="bar"></div>
                  <div className="bar"></div>
                </div>
              )}
            </div>

            <div
              className="myProgress-chat-container"
              style={{ margin: '10px 0' }}
            >
              <div
                id="chatbox"
                ref={chatBoxRef}
                style={{ height: '300px' }}
              ></div>

              <div className="myProgress-input-container">
                <input
                  type="text"
                  id="userInputSM"
                  placeholder="Type your message"
                  onKeyDown={handleSendMessageEnter}
                />
                <button
                  type="button"
                  id="send_message_soul_machine"
                  onClick={handleSendMessageClick}
                >
                  Send
                </button>
              </div>
            </div>
            {/* <div className="mute-container">
              <button
                type="button"
                id="myProgress-mute-button"
                onClick={handleMuteClick}
              >
                {isMuted ? 'Unmute Microphone' : 'Mute Microphone'}
              </button>
            </div> */}
          </div>
        </div>
      )}
      <>
        <QuizPassModal
          isOpen={isPassModalOpen}
          onClose={handleClosePassModal}
          score={quizResult?.score ?? -1}
          transcript={transcript}
        />
        <QuizFailModal
          isOpen={isFailModalOpen}
          onClose={handleCloseFailModal}
          score={quizResult?.score ?? -1}
          transcript={transcript}
        />
      </>
    </div>
  );
};

export default CourseContent;
