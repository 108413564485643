import React, { useState, useEffect, useContext } from 'react';
import { Card, Row, Col, Spinner, Badge } from 'react-bootstrap';
import './cards.css';
import { UserContext } from '../context/UserContext';
import generatePdf from './DigitalCertificate';

const MyCertificateList = ({ course }) => {
  const { user } = useContext(UserContext);
  const [certifications, setCertifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sortedCertifications, setSortedCertifications] = useState([]);
  const [isSortedDescending, setIsSortedDescending] = useState(true);

  useEffect(() => {
    const fetchCertificationDetails = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/curriculum/get-achievements/${course.id}/${user.id}`
        );
        if (response.ok) {
          const data = await response.json();

          const filteredCerts = Object.entries(data || {})
            .filter(([_, details]) => details.status)
            .map(([moduleName, details]) => ({ moduleName, ...details }));

          setCertifications(filteredCerts);
          setSortedCertifications(filteredCerts);
        } else {
          console.error('Error fetching certifications:', response.statusText);
        }
      } catch (error) {
        console.error('Error during fetch:', error);
      } finally {
        setLoading(false);
      }
    };

    if (course.id) {
      fetchCertificationDetails();
    }
  }, [course, user.id]);

  const sortCertificationsByDate = () => {
    const sorted = [...sortedCertifications].sort((a, b) => {
      const dateA = new Date(a.quiz_date);
      const dateB = new Date(b.quiz_date);
      return isSortedDescending ? dateB - dateA : dateA - dateB;
    });
    setSortedCertifications(sorted);
    setIsSortedDescending(!isSortedDescending);
  };

  if (loading) {
    return (
      <div style={{ textAlign: 'center' }}>
        <Spinner animation="border" variant="secondary" />
      </div>
    );
  }

  return (
    <div
      className="my-certificate-list"
    >
      <div style={{ textAlign: 'right', marginBottom: '20px' }}>
        <button onClick={sortCertificationsByDate} className="sort-button">
          Sort by Date {isSortedDescending ? '(Latest)' : '(Oldest)'}
        </button>
      </div>

      <Row>
        {sortedCertifications.length === 0 ? (
          <p>No certificates available</p>
        ) : (
          sortedCertifications.map(
            ({ moduleName, quiz_date, score, num_attempts, noQ }, index) => {
              const completedDate = new Date(quiz_date)
                .toISOString()
                .split('T')[0]
                .replace(/-/g, '');
              const filename = `Certificate_${course.course_name}${course.course_code}_${completedDate}.pdf`;

              return (
                <Col key={index} xs={12} md={4} className="mb-4">
                  <Card
                    className="p-2 h-100 d-flex flex-column mb-3"
                    style={{ width: '100%' }}
                  >
                    <Card.Body className="flex-grow-1 d-flex flex-column">
                      <div className="mb-auto">
                        <Card.Title className="mb-3">{moduleName}</Card.Title>
                      </div>
                      <div className="mt-auto d-flex mb-2">
                        <Card.Subtitle className="text-muted">
                          Completed on{' '}
                          {new Date(quiz_date).toLocaleDateString()}
                          <br />
                          <Badge pill bg="secondary" className="mt-1 me-2">
                            {num_attempts}
                          </Badge>
                          <span style={{ fontWeight: 'normal' }}>
                            Highest Grade: {score.toFixed(2)}%
                          </span>
                        </Card.Subtitle>
                      </div>
                      <div className="mt-auto d-flex justify-content-end align-items-center">
                        <Card.Link>
                          <button
                            className="certificate-download-button"
                            onClick={() =>
                              generatePdf(
                                score.toFixed(2),
                                noQ,
                                user.username,
                                moduleName,
                                completedDate,
                                course.course_name,
                                course.course_code,
                                course.description,
                                filename
                              )
                            }
                          >
                            <i className="fa-solid fa-download"></i>
                            Download
                          </button>
                        </Card.Link>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              );
            }
          )
        )}
      </Row>
    </div>
  );
};

export default MyCertificateList;
