import { Navigate } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import { useContext, useState, useEffect } from 'react';
import { UserContext } from './context/UserContext';

function PrivateRoute({ element, allowedRoles }) {
  const { user } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {

    if (user !== undefined) {
      setIsLoading(false);
    }
  }, [user]);

  if (isLoading) {
    return (
      <div style={{ textAlign: 'center' }}>
        <Spinner animation="border" variant="secondary" />
      </div>
    );
  }

  if (!user) {
    return <Navigate to="/" />;
  }

  const userRole = user.role;
  if (allowedRoles.includes(userRole)) {
    return element;
  }

  return <Navigate to="/" />;
}

export default PrivateRoute;