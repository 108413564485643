import React, { useState, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import InstructorNavBar from '../components/InstructorNavBar';
import SignOutButton from '../components/SignOutButton';
import { UserContext } from '../context/UserContext';
import ProfileModal from '../components/ProfileModal';
import rosterExample from '../HelpExamples/roster-example.png';
import rosterTemplate from '../HelpExamples/roster-template.csv';
import contentExample from '../HelpExamples/content-example.png';
import contentTemplate from '../HelpExamples/content-template.xlsx';
import './style.css';

const Help = () => {
    const { user } = useContext(UserContext);
    const [expandedSection, setExpandedSection] = useState(null);
    const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);

    const toggleSection = (section) => {
        setExpandedSection(expandedSection === section ? null : section);
    };

    const handleProfileClick = () => {
        setIsProfileModalOpen(true);
    };

    const handleCloseProfileModal = () => {
        setIsProfileModalOpen(false);
    };

    return (
        <div className="student-dashboard-container">
            <div className="student-view-top-container">
                <div className="logo-container">
                    <NavLink to="/learning">
                        <img
                            src="/Sofia-logo-colour.png"
                            alt="Logo"
                            className="logo"
                            draggable="false"
                        />
                    </NavLink>
                </div>
                <div className="nav-bar-container">
                    <InstructorNavBar />
                </div>
                <div className="profile-container">
                    <button className="profile-user-button" onClick={handleProfileClick}>
                        {user.profileImage ? (
                            <img
                                src={user.profileImage}
                                alt={`${user.username}`}
                                className="profile-img"
                            />
                        ) : (
                            <i className="fa-solid fa-user" style={{ fontSize: '25px', paddingRight: '15px' }}></i>
                        )}
                        <span>{user.username}</span>
                    </button>
                </div>
                <div className="signout-container">
                    <SignOutButton />
                </div>
            </div>

            <div className="help-content-container">
                <div className="help-header-container">
                    <h1 className="help-page-title">FAQs</h1>
                </div>

                <div className="help-getting-started">
                    <ul className="help-getting-started-list">

                        <li>
                            <button
                                className="toggle-button"
                                onClick={() => toggleSection('contact')}
                            >
                                Help & Support
                            </button>
                            {expandedSection === 'contact' && (
                                <div className="expanded-content">
                                    If you experience any technical issues or would like to request modifications, please contact us at <a href="mailto:coldbrew399@gmail.com" style={{ color: 'blue', textDecoration: 'underline' }}>coldbrew399@gmail.com</a>.
                                </div>
                            )}
                        </li>

                        <li>
                            <button
                                className="toggle-button"
                                onClick={() => toggleSection('sign-in')}
                            >
                                Signing In
                            </button>
                            {expandedSection === 'sign-in' && (
                                <div className="expanded-content">
                                    Initially, we planned to distinguish roles based on email domains, with instructors using <code>@auckland.ac.nz</code> and students using <code>@aucklanduni.ac.nz</code>. However, we discovered that <code>@auckland.ac.nz</code> is not a Gmail account, making it unsuitable for instructor email.
                                    <br /><br />
                                    Currently, instructors may use their personal Gmail accounts as their instructor email. Before you can sign in, please inform us of your personal Gmail address so we can add it to our <b>Test Users</b> in Google Cloud. Additionally, include your university email address (<code>@aucklanduni.ac.nz</code>) if you wish to test Soul Machines avatar.
                                </div>
                            )}
                        </li>

                        <li>
                            <button
                                className="toggle-button"
                                onClick={() => toggleSection('add-course')}
                            >
                                Adding a Course and Students
                            </button>
                            {expandedSection === 'add-course' && (
                                <div className="expanded-content">
                                    When adding a course, at least 1 instructor email should be included. You can add up to 5 instructor emails, but please note that these emails must be registered in the system before you can add them to the course.
                                    <br /><br />
                                    The student roster (downloaded from Canvas) can be uploaded when adding a course, or it can be uploaded or updated after the course has been created. Below is an example of what the student roster looks like:
                                    <img
                                        src={rosterExample}
                                        alt="Student Roster Example"
                                        style={{ maxWidth: '100%', height: 'auto' }}
                                    />
                                    <br /><br />
                                    This will create a student profile and add the student to the course if the student is not already in the system. If the student is already in the system, they will simply be added to the course.
                                    <br /><br />
                                    Students can simply sign in with their university email address without the need to register separately.
                                </div>
                            )}
                        </li>

                        <li>
                            <button
                                className="toggle-button"
                                onClick={() => toggleSection('course-content')}
                            >
                                Uploading Course Contents
                            </button>
                            {expandedSection === 'course-content' && (
                                <div className="expanded-content">
                                    You may upload course contents only if you are the instructor of the respective course. The Table of Contents must be formatted as a CSV file containing the following columns: <b>Module</b>, <b>Topic</b>, and <b>Sub-Topic</b>. Below is an example of how it looks:
                                    <br />
                                    <img
                                        src={contentExample}
                                        alt="Example Table of Contents"
                                        style={{ maxWidth: '100%', height: 'auto' }}
                                    />
                                    <br />

                                    You can download the Excel template file <a href={contentTemplate} download="content-template.xlsx" style={{ color: 'blue', textDecoration: 'underline' }}>here</a>.
                                    <br /><br />

                                    After adding contents, you can view them by clicking the <i className="fa-solid fa-gear"></i> <b>Contents</b> button. Additionally, you can set the number of questions (1-50) and the passing score (0-100) for each module quiz. The default settings are 5 questions with a passing score of 100%.
                                    <br /><br />
                                    If you wish to update the course content—such as modifying the order, adding new topics, removing existing ones, or renaming topics—you may first delete the current content. Please be aware that this action may affect any associated student progress. Once the deletion is confirmed, you can proceed to re-upload the updated content for the course.
                                </div>

                            )}
                        </li>

                        <li>
                            <button
                                className="toggle-button"
                                onClick={() => toggleSection('testing')}
                            >
                                Testing
                            </button>
                            {expandedSection === 'testing' && (
                                <div className="expanded-content">
                                    To test the Soul Machines avatar, upload the student roster and ensure the <b>Student Name</b> and <b>Email</b> (formatted as <code>@aucklanduni.ac.nz</code>) sections are updated. You don't need to worry about Student ID, Student SIS ID and Section name.
                                    <br />
                                    You can download the student roster template file <a href={rosterTemplate} download="roster-template.csv" style={{ color: 'blue', textDecoration: 'underline' }}>here</a>.
                                    <br /><br />
                                    Please ensure that your testing email is added to our <b>Test Users</b> list in Google Cloud. After successfully uploading or updating the student roster, you will be able to sign in using your student (testing) email.
                                </div>
                            )}
                        </li>

                        <li>
                            <button
                                className="toggle-button"
                                onClick={() => toggleSection('progress')}
                            >
                                Student Progress
                            </button>
                            {expandedSection === 'progress' && (
                                <div className="expanded-content">
                                    Students have the flexibility to determine the order in which they study the modules and the topics within the course. When a student clicks on a topic or subtopic, it is marked as completed or visited.
                                    <br /><br />
                                    To promote focused engagement, there is a <b>30-second time limit</b> between clicks to prevent random navigation.
                                    <br /><br />
                                    A module is deemed complete once students have successfully passed the associated quiz and have completed all topics within the module.
                                </div>
                            )}
                        </li>

                        <li>
                            <button
                                className="toggle-button"
                                onClick={() => toggleSection('quiz')}
                            >
                                Quizzes and Certifications
                            </button>
                            {expandedSection === 'quiz' && (
                                <div className="expanded-content">
                                    While students can communicate verbally or through typing during their study sessions, only verbal communication is permitted during quizzes and Interactive Oral Assessments (IOA). Typing is disabled, and chat messages are not draggable to ensure focus and integrity.
                                    <br /><br />
                                    Students have the flexibility to take the oral quiz at any time and may attempt it as many times as they wish. Only their highest score and the number of attempts will be recorded.
                                    <br /><br />
                                    Upon completing a quiz, students can download their transcript. Please note that the transcript is not stored in the database; therefore, students are advised to download it for any inquiries related to their scores or marking.
                                    <br /><br />
                                    Students can access and download their certifications from the Achievements page. They will only see the modules for which they have successfully passed the quiz, along with the number of attempts, completion date, and grade for their best attempt.
                                    <br /><br />
                                    Certifications reflect the score, completion date of the highest attempt, and the number of questions asked.
                                </div>
                            )}
                        </li>

                    </ul>
                </div>

            </div>

            <ProfileModal
                isOpen={isProfileModalOpen}
                onClose={handleCloseProfileModal}
            />
        </div>
    );
};

export default Help;
