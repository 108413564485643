import React, { useState, useEffect, useContext } from 'react';
import { Card, Row, Col, Spinner, ProgressBar } from 'react-bootstrap';
import './cards.css';
import { UserContext } from '../context/UserContext';

const MyCoursesListStud = ({ onCourseSelect }) => {
  const [courses, setCourses] = useState([]);
  const [courseLoading, setCourseLoading] = useState(true);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const { user } = useContext(UserContext);

  useEffect(() => {
    const fetchMyCourses = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/curriculum/courses/student/${user.id}`
        );

        if (response.ok) {
          const data = await response.json();
          setCourses(data.courses);
        } else {
          console.error('Error fetching courses');
        }
      } catch (error) {
        console.error('Error:', error);
      } finally {
        setCourseLoading(false);
      }
    };

    fetchMyCourses();
  }, [user.id]);

  const handleResetProgress = async (courseId) => {
    const isConfirmed = window.confirm("Are you sure you want to reset your progress for this course? Your oral quiz records will be retained.");

    if (!isConfirmed) return;

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/curriculum/reset-progress/${courseId}/${user.id}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        alert(data.message);
      } else {
        const errorData = await response.json();
        alert(`Error: ${errorData.error}`);
      }
    } catch (error) {
      console.error('Error resetting progress:', error);
      alert('An error occurred while resetting the progress');
    }
  };

  const getButtonLabel = (course) => {
    if (!course.has_started && course.completion_rate === 0) {
      return 'Start';
    } else if (course.has_started && course.completion_rate >= 0 && course.completion_rate < 100) {
      return 'Resume';
    } else if (course.has_started && course.completion_rate === 100) {
      return 'Review';
    }
  };

  if (courseLoading) {
    return (
      <div style={{ textAlign: 'center' }}>
        <Spinner animation="border" variant="secondary" />
      </div>
    );
  }

  return (
    <div className="my-courses-list">
      <Row className="mt-3">
        {courses.length === 0 ? (
          <p>No courses available</p>
        ) : (
          courses.map((course) => (
            <Col key={course.id} xs={12} md={4} className="mb-4">
              <Card
                className="p-2 h-100 d-flex flex-column"
                style={{ width: '100%' }}
              >
                <Card.Body className="flex-grow-1 d-flex flex-column">
                  <div className="mb-auto">
                    <Card.Title className="mb-3">
                      {course.course_name} {course.course_code}:{' '}
                      {course.description}
                    </Card.Title>
                    <Card.Subtitle className="mb-4 text-muted">
                      {course.semester === 3
                        ? 'Summer School'
                        : `Semester ${course.semester}`}{' '}
                      {course.year}
                    </Card.Subtitle>
                  </div>
                  <div className="w-100 mb-3 d-flex align-items-center">
                    <ProgressBar variant="rating-bar" now={course.completion_rate} className="flex-grow-1" style={{ height: '8px' }} />
                    <span className="ms-4" style={{ fontSize: '14px', color: '#777777', fontWeight: 'bold' }} >{course.completion_rate}%</span>
                  </div>
                  <div className="mt-auto d-flex justify-content-end align-items-center">
                    <Card.Link className="redo-icon" onClick={() => handleResetProgress(course.id)}>
                      <i className="fa-solid fa-rotate-right"></i>
                    </Card.Link>
                    <Card.Link>
                      <button
                        className="course-redirect-button"
                        onClick={() => {
                          setSelectedCourse(course);
                          onCourseSelect(course);
                        }}
                      >
                        {getButtonLabel(course)}
                      </button>
                    </Card.Link>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          ))
        )}
      </Row>
    </div>
  );
};

export default MyCoursesListStud;
