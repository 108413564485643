import React from 'react';
import './Modal.css';

const HelpQuizModal = ({ closeModal }) => {
  return (
    <>
      <div className="modal-background" onClick={closeModal}></div>
      <div className="help-modal">
        <div className="help-modal-content" onClick={(e) => e.stopPropagation()}>
          <span className="help-close-button" onClick={closeModal}>
            <i className="fa-solid fa-x"></i>
          </span>
          <h2>Quiz</h2>
          <p>You can take the oral quiz at any time, and as many times as you like. Only your best attempt will be recorded. If you achieve the required passing rate within the number of questions set by the instructor for each module, you will earn a digital certificate for that module, recognizing your accomplishment.
          <br /><br />
          After completing a quiz, you will have the opportunity to download your transcript. Please note that this is the only chance to download it, so we strongly recommend that you save a copy. If you have any inquiries regarding your score or the marking process, you will need to provide the downloaded transcript when contacting the instructor.</p>
        </div>
      </div>
    </>
  );
};

export default HelpQuizModal;
