import React from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const TimeAttemptsChart = ({
  labels,
  chartData,
  title,
  label,
  text,
  yValue,
}) => {
  let maxY = 0;
  if (yValue === 'minutes') {
    maxY = Math.max(...chartData) + 10;
  } else if (yValue === 'attempts') {
    maxY = Math.max(...chartData) + 1;
  }

  const data = {
    labels: Array.from({ length: labels.length }, (_, i) => (i + 1).toString()),
    datasets: [
      {
        label: label,
        data: chartData,
        backgroundColor: 'rgba(139, 0, 103, 0.3)',
        borderColor: 'rgba(139, 0, 103, 1)',
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
        display: false,
      },
      title: {
        display: true,
        text: title,
        font: {
          size: 20,
        },
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const index = context.dataIndex;
            const moduleName = labels[index].split('. ')[1];
            return `${moduleName} (${context.raw} ${yValue})`;
          },
        },
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Modules',
        },
      },
      y: {
        title: {
          display: true,
          text: text,
        },
        beginAtZero: true,
        ticks: {
          stepSize: Math.ceil(maxY / 5),
        },
        suggestedMax: maxY,
      },
    },
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        margin: '0 auto',
        width: '100%',
        height: '350px',
        padding: '10px',
      }}
    >
      <Bar data={data} options={options} />
    </div>
  );
};

export default TimeAttemptsChart;
