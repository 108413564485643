import React, { useState, useEffect } from 'react';
import { Accordion, Spinner, Table, Form } from 'react-bootstrap';
import '@fortawesome/fontawesome-free/css/all.css';
import './accordion.css';

const CourseStudentList = ({ courseId }) => {
  const [students, setStudents] = useState([]);
  const [selectedStudentId, setSelectedStudentId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [studentProgress, setStudentProgress] = useState({});
  const [quizAttempts, setQuizAttempts] = useState({});
  const [expandedModules, setExpandedModules] = useState({});
  const [expandedTopics, setExpandedTopics] = useState({});
  const [loadingProgress, setLoadingProgress] = useState(true);
  const [loadingQuizData, setLoadingQuizData] = useState({});
  const [editModuleId, setEditModuleId] = useState(null);
  const [newScore, setNewScore] = useState('');
  const [newPassStatus, setNewPassStatus] = useState('');

  const [isAtBottom, setIsAtBottom] = useState(false);

  useEffect(() => {
    const fetchCourseStudents = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/curriculum/students/${courseId}`
        );
        if (response.ok) {
          const data = await response.json();

          const sortedStudents = data.students.sort((a, b) =>
            a.last_name.localeCompare(b.last_name)
          );
          setStudents(sortedStudents);

          if (sortedStudents.length === 0) {
            setLoading(false);
          } else {
            sortedStudents.forEach((student) => {
              fetchStudentProgress(student.id);
            });
          }
        } else {
          console.error('Error fetching students');
        }
      } catch (error) {
        console.error('Error:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchCourseStudents();
  }, [courseId]);

  const fetchStudentProgress = async (studentId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/analytics/get-student-analytics/${courseId}/${studentId}`
      );
      if (response.ok) {
        const studentData = await response.json();
        setStudentProgress((prevState) => ({
          ...prevState,
          [studentId]: studentData.data,
        }));

        Object.values(studentData.data).forEach((moduleData) => {
          const moduleId = moduleData.module_id;
          if (moduleId) {
            fetchQuizData(studentId, moduleId);
          }
        });
      } else {
        console.error('Error fetching student progress');
        setStudentProgress((prevState) => ({
          ...prevState,
          [studentId]: {},
        }));
      }
    } catch (error) {
      console.error('Error:', error);
      setStudentProgress((prevState) => ({
        ...prevState,
        [studentId]: {},
      }));
    } finally {
      setLoadingProgress(false);
    }
  };

  const fetchQuizData = async (studentId, moduleId) => {
    setLoadingQuizData((prevState) => ({ ...prevState, [studentId]: true }));
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/analytics/get-best-quiz-attempt/${moduleId}/${studentId}`
      );
      if (response.ok) {
        const quizData = await response.json();
        setQuizAttempts((prevState) => ({
          ...prevState,
          [studentId]: {
            ...prevState[studentId],
            [moduleId]: quizData,
          },
        }));
      } else {
        console.error('Error fetching quiz data');
        setQuizAttempts((prevState) => ({
          ...prevState,
          [studentId]: {
            ...prevState[studentId],
            [moduleId]: {},
          },
        }));
      }
    } catch (error) {
      console.error('Error:', error);
      setQuizAttempts((prevState) => ({
        ...prevState,
        [studentId]: {
          ...prevState[studentId],
          [moduleId]: {},
        },
      }));
    } finally {
      setLoadingQuizData((prevState) => ({ ...prevState, [studentId]: false }));
    }
  };

  const handleViewProgress = (studentId) => {
    if (selectedStudentId === studentId) {
      setSelectedStudentId(null);
    } else {
      setSelectedStudentId(studentId);
      setExpandedModules({});
    }
  };

  const handleToggleModule = (moduleId) => {
    setExpandedModules((prevState) => ({
      ...prevState,
      [moduleId]: !prevState[moduleId],
    }));
  };

  const handleToggleTopic = (topicId) => {
    setExpandedTopics((prevState) => ({
      ...prevState,
      [topicId]: !prevState[topicId],
    }));
  };

  const calculateTopicCompletionRate = (topic) => {
    const totalSubtopics = topic.subtopics.length;
    if (totalSubtopics === 0) {
      return topic.is_complete ? 100 : 0;
    }

    const completedSubtopics = topic.subtopics.filter(
      (subtopic) => subtopic.is_complete
    ).length;
    return (completedSubtopics / totalSubtopics) * 100;
  };

  const getQuizStatus = (moduleData) => {
    const quizData =
      quizAttempts[selectedStudentId] &&
      quizAttempts[selectedStudentId][moduleData.module_id];

    if (!quizData || Object.keys(quizData).length === 0) {
      return 'Not Attempted';
    }

    return quizData.passed ? 'Passed' : 'Failed';
  };

  const handleEditClick = (moduleId, score, passStatus) => {
    setEditModuleId(moduleId);
    setNewScore(score);
    setNewPassStatus(passStatus ? 'Passed' : 'Not Passed');
  };

  const handleSaveClick = async (moduleId, studentId) => {
    if (newScore === '' || Number(newScore) < 0 || Number(newScore) > 100) {
      alert('Please enter a valid score between 0 and 100.');
      return;
    }

    try {
      const updateData = {
        score: newScore,
        passed: newPassStatus === 'Passed',
      };

      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/analytics/update-quiz-result/${moduleId}/${studentId}`,
        {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(updateData),
        }
      );

      if (response.ok) {
        const updatedQuizData = await response.json();
        alert('Quiz score updated successfully!');

        setQuizAttempts((prevState) => ({
          ...prevState,
          [studentId]: {
            ...prevState[studentId],
            [moduleId]: {
              ...prevState[studentId][moduleId],
              score: updatedQuizData.score,
              passed: updatedQuizData.passed,
            },
          },
        }));

        setEditModuleId(null);
      } else {
        console.error('Error updating quiz result');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const getQuizTable = (quizData, moduleId) => {
    if (!quizData || typeof quizData !== 'object') {
      return <p>No quiz data available for this module</p>;
    }

    const quizAttemptsArray = Object.values(quizData).filter(
      (attempt) => attempt.module_id !== undefined
    );
    const filteredQuizData = quizAttemptsArray.filter(
      (attempt) => attempt.module_id === moduleId
    );

    if (filteredQuizData.length === 0)
      return <p>No quiz data available for this module</p>;

    return (
      <Table striped bordered hover className="mt-3">
        <thead>
          <tr>
            <th>Quiz Start</th>
            <th>Time Spent</th>
            <th>Attempts</th>
            <th>Best Score</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {filteredQuizData.map((attempt, index) => {
            const quizStartDate = new Date(attempt.quiz_start);
            const dateString = !isNaN(quizStartDate.getTime())
              ? quizStartDate.toLocaleDateString()
              : 'Invalid Date';
            const timeString = !isNaN(quizStartDate.getTime())
              ? quizStartDate.toLocaleTimeString([], {
                hour: '2-digit',
                minute: '2-digit',
                hour12: true,
              })
              : 'Invalid Time';

            const quizEndDate = new Date(attempt.quiz_end);
            const timeSpentMs = quizEndDate - quizStartDate;
            const timeSpentMinutes = timeSpentMs / 60000;
            const timeSpentString = !isNaN(timeSpentMs)
              ? `${timeSpentMinutes.toFixed(2)} minutes`
              : 'N/A';

            return (
              <tr key={index}>
                <td>{`${dateString} ${timeString}`}</td>
                <td>{timeSpentString}</td>
                <td>{attempt.num_attempts || 'N/A'}</td>
                <td>
                  {editModuleId === attempt.module_id ? (
                    <>
                      <Form.Control
                        type="number"
                        value={newScore}
                        className="mb-2"
                        min="0"
                        max="100"
                        onChange={(e) => setNewScore(e.target.value)}
                        isInvalid={newScore !== '' && (Number(newScore) < 0 || Number(newScore) > 100)}
                      />
                    </>
                  ) : attempt.score !== undefined ? (
                    `${attempt.score.toFixed(2)}%`
                  ) : (
                    'N/A'
                  )}
                </td>

                <td>
                  <div className="edit-container">
                    {editModuleId === attempt.module_id ? (
                      <button
                        className="save-button2"
                        onClick={() =>
                          handleSaveClick(attempt.module_id, selectedStudentId)
                        }
                      >
                        Save
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="edit-button2"
                        onClick={() =>
                          handleEditClick(
                            attempt.module_id,
                            attempt.score,
                            attempt.passed
                          )
                        }
                      >
                        Edit
                      </button>
                    )}
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    );
  };

  if (loading) {
    return (
      <div style={{ textAlign: 'center' }}>
        <Spinner animation="border" variant="secondary" />
      </div>
    );
  }

  const iconStyle = {
    color: 'rgba(139, 0, 103, 0.4)',
    fontSize: '18px',
    marginLeft: '8px',
    marginRight: '8px',
  };

  const completedIcon = (
    <i className="fa-solid fa-circle-check" style={iconStyle}></i>
  );
  const notCompletedIcon = (
    <i className="fa-regular fa-circle" style={iconStyle}></i>
  );
  const arrowDownIcon = (
    <i className="fa-solid fa-chevron-down" style={iconStyle}></i>
  );
  const arrowUpIcon = (
    <i className="fa-solid fa-chevron-up" style={iconStyle}></i>
  );

  const commonCellStyle = {
    textAlign: 'right',
    fontWeight: 'bold',
    paddingRight: '10px',
  };

  const normalCellStyle = {
    textAlign: 'left',
    fontWeight: 'normal',
  };

  return (
    <div
      className="all-students-list"
      style={{ textAlign: 'left', paddingBottom: '20px' }}
    >
      {courseId && (
        <p
          style={{
            fontWeight: 'bold',
            color: '#777777',
          }}
        >
          {students.length} found
        </p>
      )}

      {courseId !== '' && (
        <Accordion className="mt-3 mb-3">
          {students.map((student, index) => (
            <Accordion.Item eventKey={index.toString()} key={student.id}>
              <Accordion.Header>
                <strong style={{ marginRight: '5px' }}>{index + 1}.</strong>{' '}
                {student.first_name} {student.last_name}
              </Accordion.Header>
              <Accordion.Body>
                <p>
                  <strong>UPI:</strong> {student.upi}
                </p>
                <p>
                  <strong>Email:</strong> {student.email}
                </p>
                <button
                  className="list-student-analytics-button"
                  onClick={() => handleViewProgress(student.id)}
                >
                  <i
                    className={
                      selectedStudentId === student.id
                        ? 'fa-regular fa-eye-slash'
                        : 'fa-regular fa-eye'
                    }
                  ></i>
                  {selectedStudentId === student.id
                    ? ' Hide Progress'
                    : ' View Progress'}
                </button>

                {selectedStudentId === student.id &&
                  studentProgress[student.id] && (
                    <>
                      <Table striped bordered hover className="mt-3">
                        <thead>
                          <tr>
                            <th>Module</th>
                            <th>Visit</th>
                            <th>Completion Rate</th>
                            <th>Quiz Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {Object.entries(studentProgress[student.id])
                            .length === 0 ? (
                            <tr>
                              <td colSpan="4">
                                No student progress data available
                              </td>
                            </tr>
                          ) : (
                            Object.entries(studentProgress[student.id])
                              .filter(
                                ([moduleName]) =>
                                  ![
                                    'course_complete',
                                    'course_completion_rate',
                                    'minutes_spent',
                                  ].includes(moduleName)
                              )
                              .map(([moduleName, moduleData], moduleIndex) => (
                                <React.Fragment
                                  key={`module-${moduleData.module_id}`}
                                >
                                  <tr
                                    onClick={() =>
                                      handleToggleModule(moduleData.module_id)
                                    }
                                  >
                                    <td>
                                      {moduleData.is_complete
                                        ? completedIcon
                                        : notCompletedIcon}
                                      <strong>
                                        {moduleIndex + 1}. {moduleName}
                                      </strong>
                                    </td>
                                    <td>
                                      {moduleData.total_no_of_clicks || 0}
                                    </td>{' '}
                                    <td
                                      style={{
                                        color: 'rgba(139, 0, 103, 0.8)',
                                        fontWeight: 'bold',
                                      }}
                                    >
                                      {moduleData.completion_rate.toFixed(2)}%
                                    </td>
                                    <td>{getQuizStatus(moduleData)}</td>
                                  </tr>
                                  {expandedModules[moduleData.module_id] && (
                                    <>
                                      {moduleData.topics.map(
                                        (topic, topicIndex) => (
                                          <React.Fragment
                                            key={`topic-${topic.topic_id}`}
                                          >
                                            <tr
                                              onClick={() =>
                                                handleToggleTopic(
                                                  topic.topic_id
                                                )
                                              }
                                            >
                                              <td
                                                style={{ paddingLeft: '2em' }}
                                              >
                                                {topic.is_complete
                                                  ? completedIcon
                                                  : notCompletedIcon}{' '}
                                                {topicIndex + 1}.{' '}
                                                {topic.topic_name}
                                                {topic.subtopics.length > 0
                                                  ? expandedTopics[
                                                    topic.topic_id
                                                  ]
                                                    ? arrowUpIcon
                                                    : arrowDownIcon
                                                  : null}
                                              </td>
                                              <td>{topic.no_of_clicks || 0}</td>{' '}
                                              <td>
                                                {calculateTopicCompletionRate(
                                                  topic
                                                ).toFixed(2)}
                                                %
                                              </td>
                                              <td></td>
                                            </tr>
                                            {expandedTopics[topic.topic_id] &&
                                              topic.subtopics.map(
                                                (subtopic, subIndex) => (
                                                  <tr
                                                    key={`subtopic-${subtopic.topic_id}`}
                                                  >
                                                    <td
                                                      style={{
                                                        paddingLeft: '4em',
                                                      }}
                                                    >
                                                      {subtopic.is_complete
                                                        ? completedIcon
                                                        : notCompletedIcon}{' '}
                                                      {topicIndex + 1}-
                                                      {subIndex + 1}.{' '}
                                                      {subtopic.topic_name}
                                                    </td>
                                                    <td>
                                                      {subtopic.no_of_clicks ||
                                                        0}
                                                    </td>
                                                    <td></td>
                                                  </tr>
                                                )
                                              )}
                                          </React.Fragment>
                                        )
                                      )}

                                      <tr>
                                        <td colSpan="4">
                                          {quizAttempts[student.id] &&
                                            typeof quizAttempts[student.id] ===
                                            'object' ? (
                                            getQuizTable(
                                              quizAttempts[student.id],
                                              moduleData.module_id
                                            )
                                          ) : (
                                            <p>No quiz attempts available</p>
                                          )}
                                        </td>
                                      </tr>
                                    </>
                                  )}
                                </React.Fragment>
                              ))
                          )}

                          {studentProgress[student.id] &&
                            Object.entries(studentProgress[student.id]).length >
                            0 && (
                              <>
                                <tr>
                                  <td colSpan="3" style={commonCellStyle}>
                                    Course Complete
                                  </td>
                                  <td colSpan="1" style={normalCellStyle}>
                                    {studentProgress[student.id].course_complete
                                      ? 'Yes'
                                      : 'No'}
                                  </td>
                                </tr>

                                <tr>
                                  <td colSpan="3" style={commonCellStyle}>
                                    Total Completion Rate
                                  </td>
                                  <td colSpan="1" style={normalCellStyle}>
                                    {
                                      studentProgress[student.id]
                                        .course_completion_rate
                                    }
                                    %
                                  </td>
                                </tr>

                                <tr>
                                  <td colSpan="3" style={commonCellStyle}>
                                    Total Time Spent
                                  </td>
                                  <td colSpan="1" style={normalCellStyle}>
                                    {studentProgress[student.id].minutes_spent}{' '}
                                    minutes
                                  </td>
                                </tr>
                              </>
                            )}
                        </tbody>
                      </Table>
                    </>
                  )}
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      )}
    </div>
  );
};

export default CourseStudentList;
