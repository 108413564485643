import React from 'react';
import { ProgressBar } from 'react-bootstrap';
import './Modal.css';
import generateTranscript from './transcript';

const QuizFailModal = ({ isOpen, onClose, score, transcript }) => {
  if (!isOpen) return null;

  const handleTranscriptClick = () => {
    generateTranscript(transcript);
  };

  return (
    <div className="quiz-pass-modal-background" onClick={onClose}>
      <div className="quiz-pass-modal-content" onClick={(e) => e.stopPropagation()}>
        <button className="quiz-pass-close-button" onClick={onClose}>
          <i className="fa-solid fa-x"></i>
        </button>
        <h2 className="quiz-pass-modal-title">You didn't pass this time</h2>
        <p className="quiz-pass-subtitle">But don't give up!</p>
        <br></br>

        <p>Your score: {score}%</p>
        <div style={{ width: '80%', margin: '0 auto' }}>
          <ProgressBar variant="quiz-result-bar" now={score} />
        </div>

        <div className="quiz-pass-buttons">
          <br />
          <button
            className="course-redirect-button"
            style={{ width: '200px' }}
            onClick={handleTranscriptClick}
          >
            <i
              className="fa-solid fa-download"
              style={{ marginRight: '8px', fontSize: '18px' }}
            ></i>
            Transcript
          </button>

          <button className="go-to-achievements-button" onClick={onClose}>
            Study Again
          </button>
        </div>

        <p className="transcript-explanation">
          <strong>This is your only chance to download your transcript.</strong>
          <br />
          If you have any inquiries about your marking or score, please download
          it and email the instructor.
        </p>
      </div>
    </div>
  );
};

export default QuizFailModal;
