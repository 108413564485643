import { Scene, Persona, ConversationState, ConversationStateTypes } from '@soulmachines/smwebsdk';

//const [showButtons, setShowButtons] = useState(true);

const apiKeyLearning = process.env.REACT_APP_SOUL_MACHINES_API_KEY_LEARNING;
const apiKeyIOA = process.env.REACT_APP_SOUL_MACHINES_API_KEY_IOA;
let apiKEY = apiKeyLearning;

let scene;
let sessionId;
let persona;
// let listenersAdded = false;
let chat;
let videoEl;
let SMBool = { upload: true };
let userBool = { upload: true };
let isQuiz = true;
let passingScore;
let quizQuestions;
let turns = 0;
let startTime = new Date().getTime();
let current_module;
let currentCourseId;
let currentStudentId;

let userId;
let currentModuleId;
let quizStartTime;
let quizEndTime;

let sendBox;
let sendButton;

export let transcript = [];

export async function connectToSM(v, chatbox, username, course_id, student_id) {
  currentCourseId = course_id;
  currentStudentId = student_id;
  if (transcript != null) transcript = [];
  if (persona != null) persona = null;
  if (chat != null) {
    chat.innerHTML = '';
    chat = null;
  }
  if (videoEl != null) videoEl = null;
  closeScene();
  chat = chatbox;
  videoEl = v;

  if (!videoEl) {
    console.warn('Video element not found');
    return;
  }
  console.log(`existing sessionid:${sessionId}`);

  scene = new Scene({
    apiKey: apiKEY,
    videoElement: videoEl,
    requestedMediaDevices: { microphone: true, camera: true },
    requiredMediaDevices: {},
  });
  console.log('new scene object created');

  try {
    sessionId = await scene.connect();
    console.log('waiting finished');

    console.log(
      `>> Is Session Persistence Supported: ${scene.supportsSessionPersistence()}\n>> Is Current Session Resumed Session: ${scene.isResumedSession()}`
    );
    onConnectionSuccess(sessionId, username);
  } catch (error) {
    onConnectionError(error);
  }
  return scene;
}

export function closeScene() {
  if (scene) scene.close();
}

function scrollToBottom() {
  chat.scrollTop = chat.scrollHeight;
}

function onConnectionSuccess(sessionId, username) {
  console.info('Successfully connected, session id:', sessionId);

  scene
    .startVideo()
    .then((videoState) => {
      console.info('Video started with state:', videoState);
    })
    .catch((error) => console.warn('Could not start video:', error));

  console.log(`persona is : ${persona != null}`);
  persona = new Persona(scene, sessionId);

  persona.onSpeechMarkerEvent.addListener(onSpeechMarker);

  console.log('no event listener');

  scene.onRecognizeResultsEvent.addListener(
    (scene, status, errorMessage, results) => {
      const result = results[0];
      const userSpeech = result.alternatives[0].transcript;

      if (result.final === true && userBool.upload === true) {
        runTimer(1000, userBool);
        console.log('[userSpeech] user said:', userSpeech);

        if (
          transcript.length > 0 &&
          transcript[transcript.length - 1].source === 'persona'
        ) {
          turns += 1;
        }
        transcript.push({ source: 'user', text: userSpeech });
        const userDiv = document.createElement('div');
        userDiv.classList.add('chat-bubble', 'user');
        // userDiv.innerHTML = `<strong>You:</strong> ${userSpeech}`;
        userDiv.innerHTML = `${userSpeech}`;
        chat.appendChild(userDiv);
        scrollToBottom();
        console.log('[transcript]: ', transcript);
      }
    }
  );

  function convertToBold(text) {
    return text.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
  }

  scene.onStateEvent.addListener((scene, event) => {
    const personaState = event.persona?.['1'];

    if (personaState?.speechState === 'speaking' && SMBool.upload === true) {
      runTimer(1000, SMBool);
      const personaSpeech = personaState?.currentSpeech;
      console.log('[personaSpeech]', personaSpeech);

      const formattedText = convertToBold(personaSpeech);

      transcript.push({ source: 'persona', text: personaSpeech });

      const personaContainer = document.createElement('div');
      personaContainer.classList.add('chat-bubble-container');

      const nameDiv = document.createElement('div');
      nameDiv.classList.add('persona-name');
      nameDiv.innerText = 'Sofia';

      const personaDiv = document.createElement('div');
      personaDiv.classList.add('chat-bubble', 'persona');
      // personaDiv.innerHTML = <strong>Sofia:</strong> ${formattedText};
      personaDiv.innerHTML = `${formattedText}`;

      personaContainer.appendChild(nameDiv);
      personaContainer.appendChild(personaDiv);

      chat.appendChild(personaContainer);
      // scrollToBottom();
      const topPosition = personaContainer.getBoundingClientRect().top - chat.getBoundingClientRect().top + chat.scrollTop;
      chat.scrollTo({ top: topPosition, behavior: 'smooth' });

      console.log('[transcript]: ', transcript);

      if (
        isQuiz === false &&
        personaSpeech.toLowerCase().includes('your score for this quiz')
      ) {
        isQuiz = true;
        let scoreMatch = personaSpeech.match(/(\d+(\.\d+)?)%/);
        console.log('Scorematch: ' + scoreMatch);

        if (scoreMatch) {
          quizEndTime = new Date().toISOString();
          console.log('Quiz end time: ', quizEndTime);

          const requestData = {
            grade: parseFloat(scoreMatch),
            start: quizStartTime,
            end: quizEndTime,
          };
          console.log('requestData: ', JSON.stringify(requestData));

          fetch(
            `${process.env.REACT_APP_BACKEND_URL
            }/curriculum/add-quiz-attempt/${currentModuleId}/${parseInt(
              userId,
              10
            )}`,
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(requestData),
            }
          )
            .then((response) => response.json())
            .then((data) => {
              console.log('Success:', data);
            })
            .catch((error) => {
              console.error('Error:', error);
            });

          if (window.quizResultCallback) {
            window.quizResultCallback(parseFloat(scoreMatch));
          }

          activateKeyboardInput(sendBox, sendButton);
        }
      }
    }
  });

  scene.onStateEvent.removeListener();
  scene.onRecognizeResultsEvent.removeListener();
}

async function runTimer(ms, bool) {
  bool.upload = false;
  await new Promise((resolve) => setTimeout(resolve, ms));
  bool.upload = true;
}

export const toggleMute = async () => {
  console.log('toggled');
  if (scene) {
    console.log('scene exists');
    if (scene.isMicrophoneActive()) {
      await scene.setMediaDeviceActive({ microphone: false });
      console.log('muted');
      return true;
    } else {
      await scene.setMediaDeviceActive({ microphone: true });
      console.log('unmuted');
      return false;
    }
  }
  return;
};
export const toggleCamera = async () => {
  console.log('toggled');
  if (scene) {
    console.log('scene exists');
    if (scene.isCameraActive()) {
      await scene.setMediaDeviceActive({ camera: false });
      console.log('cam off');
      return false;
    } else {
      await scene.setMediaDeviceActive({ camera: true });
      console.log('cam on');
      return true;
    }
  }
  return;
};

export const toggleIOA = async () => {
  console.log('switching');
  if (apiKEY === apiKeyLearning) {
    apiKEY = apiKeyIOA;
    return true;
  } else {
    apiKEY = apiKeyLearning;
    return false;
  }
};

function onConnectionError(error) {
  switch (error.name) {
    case 'noUserMedia':
      console.warn('User blocked device access or no devices available');
      alert(
        'Unable to access camera or microphone. Please check your device settings and permissions.'
      );
      break;
    case 'noScene':
    case 'serverConnectionFailed':
      console.warn('Server connection failed');
      break;
    case 'noSessionToResume':
      console.warn(
        'No session to resume. Please ensure the session is correctly created and managed.'
      );
      sessionStorage.removeItem('sm-session-id');
      connectToSM(videoEl, chat);
      break;
    default:
      console.warn('Unhandled error:', error);
  }
}

function onSpeechMarker(persona, message) {
  const markerType = message.name;
  const cardIds = message.arguments;

  if (markerType === 'hidecards') {
    if (cardIds.length === 0) {
      console.log('hide all cards');
    } else {
      console.log('hide these cards:', cardIds);
    }
  } else if (markerType === 'showcards') {
    console.log('show these cards:', cardIds);
  }
}

export function onSendMessage(textInput, course_id, student_id) {
  currentCourseId = course_id;
  currentStudentId = student_id;

  const userText = textInput.value;

  if (userText.length > 0) {
    persona.conversationSend(userText, {}, {});
    console.log('[userText] user typed:', userText);

    console.log('spurce: ' + transcript[transcript.length - 1].source);
    if (
      transcript.length > 0 &&
      transcript[transcript.length - 1].source === 'persona'
    ) {
      turns += 1;
    }

    transcript.push({ source: 'user', text: userText });
    const userDiv = document.createElement('div');
    userDiv.classList.add('chat-bubble', 'user');
    // userDiv.innerHTML = `<strong>You:</strong> ${userText}`;
    userDiv.innerHTML = `${userText}`;
    chat.appendChild(userDiv);
    scrollToBottom();
    console.log('[transcript]: ', transcript);
  }

  textInput.value = '';
}

export function onSendMessageTopic(textInput, course_id, student_id, mode) {
  currentCourseId = course_id;
  currentStudentId = student_id;
  if (isQuiz) {
    if (mode === 'learning') {
      let string1 = 'I want to learn about ';
      let string2 = ' in digital marketing.';
      const userText = string1 + textInput + string2;
      console.log(userText.length);
      persona.conversationSend(userText, {}, {});
      console.log('[userText] user typed:', userText);

      transcript.push({ source: 'Learning', text: userText });
      const userDiv = document.createElement('div');
      userDiv.classList.add('chat-bubble', 'user');
      // userDiv.innerHTML = `<strong>You:</strong> ${userText}`;
      userDiv.innerHTML = `${userText}`;
      chat.appendChild(userDiv);
      scrollToBottom();
      console.log('[transcript]: ', transcript);
    }
    if (mode === 'IOA') {
      console.log('This is IOA prompt');
    }
  }
}

export function generateQuiz(
  user_id,
  module,
  module_id,
  topics,
  noQ,
  passScore,
  onQuizResult,
  onTranscript
) {
  if (isQuiz) {
    isQuiz = false;
    passingScore = passScore;
    quizQuestions = noQ;
    userId = user_id;
    current_module = module;
    currentModuleId = module_id;
    const userText = `Generate Quiz (${noQ}, ${passScore}%, ${module} covering ${topics}).`;
    console.log('This is usertext: ' + userText);
    persona.conversationSend(userText, {}, {});
    console.log('[quiz] user wants to take quizzes on: ', module);

    transcript = [];
    transcript.push({ source: 'Learning', text: userText });
    chat.innerHTML = '';
    console.log('[transcript]: ', transcript);

    quizStartTime = new Date().toISOString();
    console.log('Quiz start time: ', quizStartTime);

    window.quizResultCallback = (score, moduleName, moduleId) => {
      onQuizResult(score, moduleName, moduleId);

      if (onTranscript) {
        onTranscript(transcript);
      }
    };
  }
}

export function generateIOA(user_id, topics, module = null, module_id = null) {
  let userText;
  userId = user_id;
  if (module && module_id) {
    current_module = module;
    currentModuleId = module_id;
    userText = `Begin IOA on module: ${module} covering topics: ${topics}.`;
  } else {
    userText = `Begin IOA on topic: ${topics}.`;
  }
  console.log('This is usertext: ' + userText);
  persona.conversationSend(userText, {}, {});
  transcript.push({ source: 'Learning', text: userText });
  chat.innerHTML = '';
  console.log('[transcript]: ', transcript);
}

export function deactivateKeyboardInput(textbox, send) {
  console.log('HI');
  sendBox = textbox;
  sendButton = send;
  sendBox.disabled = true;
  sendButton.disabled = true;
  chat.style.userSelect = 'none';
}

export function activateKeyboardInput(sendbox, sendbutton) {
  console.log('HI');
  sendBox = sendbox;
  sendButton = sendbutton;
  sendBox.disabled = false;
  sendButton.disabled = false;
  chat.style.userSelect = 'text';
  const listItems = chat.querySelectorAll('li');
  listItems.forEach((item) => {
    item.style.userSelect = 'text';
  });
}

export function getCurrentTime() {
  return new Date().getTime() - startTime;
}

export function getTurns() {
  return turns;
}

export function updateSMData(courseId, studentId) {
  console.log('updating sm data.......');
  const EndTime = new Date().getTime();
  currentCourseId = courseId;
  currentStudentId = studentId;

  const requestData = {
    student_id: currentStudentId,
    course_id: currentCourseId,
    turns: turns,
    start_time: startTime,
    end_time: EndTime,
  };

  startTime = EndTime;
  console.log('requestData: ', JSON.stringify(requestData));

  fetch(`${process.env.REACT_APP_BACKEND_URL}/analytics/update-sm-data`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(requestData),
  })
    .then((response) => response.json())
    .then((data) => {
      console.log('Success:', data);
    })
    .catch((error) => {
      console.error('Error:', error);
    });
}
