import React, { useState, useEffect, useContext } from 'react';
import {
  Accordion,
  Form,
  Spinner,
  Row,
  Col,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import ContentModal from './ContentModal';
import './accordion.css';

import { UserContext } from '../context/UserContext';

const MyCoursesListInst = ({ reload }) => {
  const [courses, setCourses] = useState([]);
  const [csvFile, setCsvFile] = useState(null);
  const [rosterFile, setRosterFile] = useState(null);
  const [courseLoading, setCourseLoading] = useState(true);
  const [uploading, setUploading] = useState(false);
  const [uploadingRoster, setUploadingRoster] = useState(false);
  const [showContentModal, setShowContentModal] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [showRosterForm, setShowRosterForm] = useState(false);
  const { user } = useContext(UserContext);

  const fetchMyCourses = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/curriculum/courses/instructor/${user.id}`
      );

      if (response.ok) {
        const data = await response.json();
        setCourses(data.courses);
      } else {
        console.error('Error fetching courses');
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setCourseLoading(false);
    }
  };

  const deleteCourse = async (courseId) => {
    const confirmDelete = window.confirm(
      'Are you sure you want to delete this course?'
    );
    if (!confirmDelete) return;

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/curriculum/courses/${courseId}/delete`,
        {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.status === 204) {
        alert('Course deleted successfully!');
        setCourses(courses.filter((course) => course.id !== courseId));
      } else {
        const data = await response.json();
        alert(`Error: ${data.error}`);
      }
    } catch (error) {
      console.error('Error deleting course:', error);
      alert('Error deleting course');
    }
  };

  useEffect(() => {
    fetchMyCourses();
  }, [user.id, reload]);

  const Link = ({ id, children, title }) => (
    <OverlayTrigger
      placement="bottom"
      overlay={<Tooltip id={id}>{title}</Tooltip>}
    >
      <a href="#">{children}</a>
    </OverlayTrigger>
  );

  const handleFileChange = (e) => {
    setCsvFile(e.target.files[0]);
  };

  const handleRosterFileChange = (e) => {
    setRosterFile(e.target.files[0]);
  };

  const handleFileUpload = async (course_id) => {
    if (!csvFile) {
      alert('Please select a file first!');
      return;
    }

    setUploading(true);

    const tableOfContentsData = new FormData();
    tableOfContentsData.append('course_id', course_id);
    tableOfContentsData.append('file', csvFile);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/curriculum/upload-content/${course_id}`,
        {
          method: 'POST',
          body: tableOfContentsData,
        }
      );
      const data = await response.json();

      if (response.ok) {
        alert(data.message);
        console.table(data.data);
        setCsvFile(null);
        document.querySelector('input[type="file"]').value = '';
      } else {
        alert(`Error: ${data.error}`);
        console.error('Error uploading file: ', data.error);
      }
    } catch (error) {
      alert(error);
      console.error('Error:', error);
    } finally {
      setUploading(false);
    }
  };

  const handleRosterUpload = async (course_id) => {
    if (!rosterFile) {
      alert('Please select a file first!');
      return;
    }

    const confirmationMessage = `
    You are about to update the student roster for this course. Please note the following:
    - New students in the roster will be added to the course.
    - Existing students will retain their progress in the course.
    - Students not listed in the new roster will be removed from the course, and their progress will be deleted.
    Do you wish to proceed with these changes?
  `;

    const confirmUpdate = window.confirm(confirmationMessage);

    if (confirmUpdate) {
      setUploadingRoster(true);

      const rosterData = new FormData();
      rosterData.append('course_id', course_id);
      rosterData.append('file', rosterFile);

      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/curriculum/update-roster/${course_id}`,
          {
            method: 'POST',
            body: rosterData,
          }
        );

        const data = await response.json();

        if (response.ok) {
          alert(data.message);
          setRosterFile(null);
          document.querySelector('input[type="file"]').value = '';

          fetchMyCourses();
        } else {
          alert(`Error: ${data.error}`);
          console.error('Error uploading student roster: ', data.error);
        }
      } catch (error) {
        alert('Error uploading roster');
        console.error('Error:', error);
      } finally {
        setUploadingRoster(false);
      }
    }
  };

  const handleManageClick = (course) => {
    setSelectedCourse(course);
    setShowContentModal(true);
  };

  const handleCloseModal = () => {
    setShowContentModal(false);
    setSelectedCourse(null);
  };

  const toggleRosterForm = () => {
    setShowRosterForm((prev) => !prev);
  };

  if (courseLoading) {
    return (
      <div style={{ textAlign: 'center' }}>
        <Spinner animation="border" variant="secondary" />
      </div>
    );
  }

  return (
    <div className="my-courses-list" style={{ paddingBottom: '20px' }}>
      {courses.length === 0 ? (
        <p style={{ textAlign: 'left', padding: '20px 0' }}>
          No courses found. Please contact the administrator or create a new course.
        </p>
      ) : (
        <Accordion className="mb-3 mt-3">
          {courses.map((course) => (
            <Accordion.Item key={course.id} eventKey={course.id}>
              <Accordion.Header>
                <div style={{ flex: 1 }}>
                  <h5>
                    {course.course_name} {course.course_code}:{' '}
                    {course.description}
                  </h5>
                  <p>
                    {course.semester === 3
                      ? 'Summer School'
                      : `Semester ${course.semester}`}{' '}
                    {course.year}
                  </p>
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <p>
                  <b>{course.instructor_count}</b>{' '}
                  {course.instructor_count > 1 ? 'instructors' : 'instructor'}
                </p>
                <p>
                  <b>{course.student_count}</b>{' '}
                  {course.student_count > 1 ? 'students' : 'student'}
                </p>
                <br />

                <p>
                  <button
                    type="button"
                    onClick={toggleRosterForm}
                    style={{
                      background: 'none',
                      border: 'none',
                      color: '#2b2b2b',
                      cursor: 'pointer',
                      textDecoration: 'underline',
                      fontWeight: 'bold',
                      padding: '0',
                    }}
                  >
                    Update Student Roster
                    <span style={{ marginLeft: '5px' }}>
                      <Link
                        title="Please upload a CSV file downloaded from Canvas. The file should include Student Name and Email."
                      >
                        <i className="fa-solid fa-circle-info"></i>
                      </Link>
                    </span>
                  </button>
                </p>

                {showRosterForm && (
                  <Form className="mb-3" style={{ margin: 0 }}>
                    <Row className="align-items-center mb-2">
                      <Col xs="auto" className="d-flex mb-2">
                        <Form.Control
                          type="file"
                          accept=".csv"
                          onChange={(e) => handleRosterFileChange(e, course.id)}
                        />
                      </Col>
                      <Col xs="auto" className="d-flex mb-2">
                        <button
                          type="button"
                          className="upload-button"
                          onClick={() => handleRosterUpload(course.id)}
                          disabled={uploadingRoster}
                        >
                          {uploadingRoster ? (
                            <div style={{ textAlign: 'center' }}>
                              <Spinner animation="border" variant="secondary" />
                            </div>
                          ) : (
                            'Upload'
                          )}
                        </button>
                      </Col>
                    </Row>
                  </Form>
                )}

                <Form className="mb-3" style={{ margin: 0 }}>
                  <p>
                    <b>
                      Table of Contents{' '}
                      <Link title="Please upload a CSV file containing the table of contents. Ensure that your file is formatted with the following columns: Module, Topic and Sub-topic. ">
                        <i className="fa-solid fa-circle-info"></i>
                      </Link>{' '}
                    </b>
                  </p>
                  <Row className="align-items-center mb-2">
                    <Col xs="auto" className="d-flex mb-2">
                      <Form.Control
                        type="file"
                        accept=".csv"
                        onChange={(e) => handleFileChange(e, course.id)}
                      />
                    </Col>
                    <Col xs="auto" className="d-flex mb-2">
                      <button
                        type="button"
                        className="upload-button"
                        onClick={() => handleFileUpload(course.id)}
                        disabled={uploading}
                      >
                        {uploading ? (
                          <div style={{ textAlign: 'center' }}>
                            <Spinner animation="border" variant="secondary" />
                          </div>
                        ) : (
                          'Upload'
                        )}
                      </button>
                    </Col>
                    <Col xs="auto" className="d-flex mb-2">
                      <button
                        type="button"
                        className="manage-button"
                        onClick={() => handleManageClick(course)}
                      >
                        <i className="fa-solid fa-gear"></i>Contents
                      </button>
                    </Col>
                  </Row>
                </Form>

                <br />
                <button
                  type="button"
                  className="delete-button"
                  onClick={() => deleteCourse(course.id)}
                >
                  Delete Course
                </button>
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      )}

      <ContentModal
        isOpen={showContentModal}
        closeModal={handleCloseModal}
        course={selectedCourse}
      />
    </div>
  );
};

export default MyCoursesListInst;
