import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '@fortawesome/fontawesome-free/css/all.min.css';


const SignOutButton = () => {
    const navigate = useNavigate();
    const [isSmallMediumScreen, setIsSmallMediumScreen] = useState(window.innerWidth < 1200);
    const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    const handleResize = () => {
        setIsSmallMediumScreen(window.innerWidth < 1200);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  
    const handleSignOut = async () => {
        sessionStorage.clear();
        navigate('/');
    };

    const buttonStyle = {
        width: '100px',
        margin: '0 auto',
        color: isHovered ? '#2b2b2b' : '#ffffff',
        padding: '7px 15px',
        fontFamily: "'Roboto', sans-serif",
        fontSize: '16px',
        fontWeight: '500',
        borderRadius: '50px',
        border: '2px #2b2b2b solid',
        textDecoration: 'none',
        display: 'inline-block',
        textAlign: 'center',
        boxSizing: 'border-box',
        backgroundColor: isHovered ? 'transparent' : '#2b2b2b',
        cursor: 'pointer',
        transition: 'background-color 0.3s ease, color 0.3s ease',
    }

    const iconStyle = {
        backgroundColor: isHovered ? 'transparent' : '#2b2b2b',
        color: isHovered ? '#2b2b2b' : '#ffffff',
        padding: '8px',
        borderRadius: '50px',
        border: '2px #2b2b2b solid',
        fontSize: '18px',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '40px',
        height: '40px',
        cursor: 'pointer',
        transition: 'background-color 0.3s ease, color 0.3s ease',
    }
  
    return (
        <button
          onClick={handleSignOut}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          style={isSmallMediumScreen ? iconStyle : buttonStyle}>
        {isSmallMediumScreen ? (
          <i className="fa-solid fa-right-from-bracket"></i>
        ) : (
          'Sign Out'
        )}
      </button>
    );
  };
  
  export default SignOutButton;