import React from 'react';
import './Modal.css';

const HelpSupportModal = ({ closeModal }) => {
  return (
    <>
      <div className="modal-background" onClick={closeModal}></div>
      <div className="help-modal">
        <div className="help-modal-content" onClick={(e) => e.stopPropagation()}>
          <span className="help-close-button" onClick={closeModal}>
            <i className="fa-solid fa-x"></i>
          </span>
          <h2>Instructor Insights and Support</h2>
          <p>
            To help improve your learning experience, instructors have access to
            advanced tools that allow them to monitor your engagement with
            Sofia. This means that your interactions, progress, and performance
            data are shared with your instructors.
            <br /><br />
            By analyzing this data, instructors can better understand your
            learning needs and provide targeted support to help you succeed in
            your courses. This ensures that you receive personalized feedback
            and guidance based on your unique learning journey.
          </p>
        </div>
      </div>
    </>
  );
};

export default HelpSupportModal;
