import React, { useContext, useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import StudentNavBar from '../components/StudentNavBar';
import SignOutButton from '../components/SignOutButton';
import ProfileModal from '../components/ProfileModal';
import { UserContext } from '../context/UserContext';
import { Form } from 'react-bootstrap';
import './style.css';
import MyCertificateList from '../components/MyCertificateList';

const Achievements = () => {
  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);
  const { user } = useContext(UserContext);
  const [courses, setCourses] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState(null);

  useEffect(() => {
    const fetchMyCourses = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/curriculum/courses/student/${user.id}`
        );
        if (response.ok) {
          const data = await response.json();

          setCourses(data.courses);
        } else {
          console.error('Error fetching courses');
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchMyCourses();
  }, [user.id]);

  const handleProfileClick = () => {
    setIsProfileModalOpen(true);
  };

  const handleCloseProfileModal = () => {
    setIsProfileModalOpen(false);
  };

  const handleCourseChange = (event) => {
    const selectedCourseId = parseInt(event.target.value, 10);
    const course = courses.find((c) => c.id === selectedCourseId);
    setSelectedCourse(course);
  };

  return (
    <div className="student-dashboard-container" id="achievements-container">
      <div className="student-view-top-container">
        <div className="logo-container">
          <NavLink to="/learning">
            <img
              src="/Sofia-logo-colour.png"
              alt="Logo"
              className="logo"
              draggable="false"
            />
          </NavLink>
        </div>
        <div className="nav-bar-container">
          <StudentNavBar />
        </div>
        <div className="profile-container">
          <button className="profile-user-button" onClick={handleProfileClick}>
            {user.profileImage ? (
              <img
                src={user.profileImage}
                alt={`${user.username}`}
                className="profile-img"
              />
            ) : (
              <i className="fa-solid fa-user" style={{ fontSize: '25px', paddingRight: '15px' }}></i>
            )}
            <span>{user.username}</span>
          </button>
        </div>
        <div className="signout-container">
          <SignOutButton />
        </div>
      </div>

      <div className="content-container">
        <p className="page-title">Achievements</p>

        <Form className="mt-3 mb-3">
          <Form.Select
            className="my-course-select mb-2"
            value={selectedCourse ? selectedCourse.id : ''}
            onChange={handleCourseChange}
          >
            <option value="" disabled hidden>
              Select a course
            </option>
            {courses.map((course) => (
              <option key={course.id} value={course.id}>
                {course.course_name} {course.course_code} (
                {course.semester === 1
                  ? 'S1'
                  : course.semester === 2
                    ? 'S2'
                    : 'SS'}{' '}
                {course.year})
              </option>
            ))}
          </Form.Select>
        </Form>

        {selectedCourse && <MyCertificateList course={selectedCourse} />}
      </div>

      <ProfileModal
        isOpen={isProfileModalOpen}
        onClose={handleCloseProfileModal}
      />
    </div>
  );
};

export default Achievements;
