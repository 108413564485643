import React, { useState, useContext, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import StudentNavBar from '../components/StudentNavBar';
import ProfileModal from '../components/ProfileModal';
import SignOutButton from '../components/SignOutButton';
import { UserContext } from '../context/UserContext';
import MyCoursesListStud from '../components/MyCoursesListStud';
import CourseContent from '../components/CourseContent';
import './style.css';

const MyProgress = () => {
  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const { user } = useContext(UserContext);

  useEffect(() => {
    const storedCourse = sessionStorage.getItem('selectedCourse');
    if (storedCourse) {
      setSelectedCourse(JSON.parse(storedCourse));
    }
  }, []);

  const handleProfileClick = () => {
    setIsProfileModalOpen(true);
  };

  const handleCloseProfileModal = () => {
    setIsProfileModalOpen(false);
  };

  const handleCourseSelect = (course) => {
    setSelectedCourse(course);
    if (course) {
      sessionStorage.setItem('selectedCourse', JSON.stringify(course));
    } else {
      sessionStorage.removeItem('selectedCourse');
    }
  };

  return (
    <div className="student-dashboard-container" id="myProgress-container">
      <div className="student-view-top-container">
        <div className="logo-container">
          <NavLink to="/">
            <img
              src="/Sofia-logo-colour.png"
              alt="Logo"
              className="logo"
              draggable="false"
            />
          </NavLink>
        </div>
        <div className="nav-bar-container">
          <StudentNavBar />
        </div>
        <div className="profile-container">
          <button className="profile-user-button" onClick={handleProfileClick}>
            {user.profileImage ? (
              <img
                src={user.profileImage}
                alt={`${user.username}`}
                className="profile-img"
              />
            ) : (
              <i className="fa-solid fa-user" style={{ fontSize: '25px', paddingRight: '15px' }}></i>
            )}
            <span>{user.username}</span>
          </button>
        </div>
        <div className="signout-container">
          <SignOutButton />
        </div>
      </div>

      <div className="content-container">
        {selectedCourse ? (
          <div className="course-content-container" style={{ margin: '20px' }}>
            <button
              className="back-to-course-redirect-button z-index-button"
              onClick={() => {
                handleCourseSelect(null);
              }}
            >
              <i className="fa-solid fa-chevron-left"></i> Back
            </button>
            <CourseContent selectedCourse={selectedCourse} />
          </div>
        ) : (
          <>
            <p className="page-title">My Courses</p>
            <MyCoursesListStud onCourseSelect={handleCourseSelect} />
          </>
        )}
      </div>

      <ProfileModal
        isOpen={isProfileModalOpen}
        onClose={handleCloseProfileModal}
      />
    </div>
  );
};

export default MyProgress;
