import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import './App.css';
import Home from './pages/Home';
import MyProgress from './pages/MyProgress';
import Achievements from './pages/Achievements';
import Feedback from './pages/Feedback';
import Help from './pages/Help';
import Dashboard from './pages/Dashboard';
import Courses from './pages/Courses';
import FeedbackInst from './pages/FeedbackInst';
import HelpInst from './pages/HelpInst';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { UserProvider } from './context/UserContext';
import PrivateRoute from './PrivateRoute';

function App() {
  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <UserProvider>
        <BrowserRouter>
          <div className="App">
            <Routes>
              {/* Public routes */}
              <Route path="/" element={<Home />} />

              {/* Routes for students */}
              <Route path="/learning" element={<PrivateRoute element={<MyProgress />} allowedRoles={['student']} />} />
              <Route path="/achievements" element={<PrivateRoute element={<Achievements />} allowedRoles={['student']} />} />
              <Route path="/feedback" element={<PrivateRoute element={<Feedback />} allowedRoles={['student']} />} />
              <Route path="/help" element={<PrivateRoute element={<Help />} allowedRoles={['student']} />} />

              {/* Routes for instructors */}
              <Route path="/manage" element={<PrivateRoute element={<Dashboard />} allowedRoles={['instructor']} />} />
              <Route path="/manage/courses" element={<PrivateRoute element={<Courses />} allowedRoles={['instructor']} />} />
              <Route path="/manage/feedback" element={<PrivateRoute element={<FeedbackInst />} allowedRoles={['instructor']} />} />
              <Route path="/manage/help" element={<PrivateRoute element={<HelpInst />} allowedRoles={['instructor']} />} />

              {/* Default route if no match */}
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </div>
        </BrowserRouter>
      </UserProvider>
    </GoogleOAuthProvider>
  );
}

export default App;