import React, { useState, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import StudentNavBar from '../components/StudentNavBar';
import SignOutButton from '../components/SignOutButton';
import { UserContext } from '../context/UserContext';
import HelpQuizModal from '../components/Help-Quiz-Modal';
import HelpSupportModal from '../components/Help-Support-Modal';
import HelpGuideModal from '../components/Help-Guide-Modal';
import ProfileModal from '../components/ProfileModal';
import mode1 from '../HelpExamples/mode1.png';
import mode2 from '../HelpExamples/mode2.png';
import './style.css';

const Help = () => {
  const { user } = useContext(UserContext);
  const [expandedSection, setExpandedSection] = useState(null);
  const [modalContent, setModalContent] = useState(null);
  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);

  const toggleSection = (section) => {
    setExpandedSection(expandedSection === section ? null : section);
  };

  const openModal = (content) => {
    setModalContent(content);
  };

  const closeModal = () => {
    setModalContent(null);
  };

  const handleProfileClick = () => {
    setIsProfileModalOpen(true);
  };

  const handleCloseProfileModal = () => {
    setIsProfileModalOpen(false);
  };

  return (
    <div className="student-dashboard-container">
      <div className="student-view-top-container">
        <div className="logo-container">
          <NavLink to="/learning">
            <img
              src="/Sofia-logo-colour.png"
              alt="Logo"
              className="logo"
              draggable="false"
            />
          </NavLink>
        </div>
        <div className="nav-bar-container">
          <StudentNavBar />
        </div>
        <div className="profile-container">
          <button className="profile-user-button" onClick={handleProfileClick}>
            {user.profileImage ? (
              <img
                src={user.profileImage}
                alt={`${user.username}`}
                className="profile-img"
              />
            ) : (
              <i className="fa-solid fa-user" style={{ fontSize: '25px', paddingRight: '15px' }}></i>
            )}
            <span>{user.username}</span>
          </button>
        </div>
        <div className="signout-container">
          <SignOutButton />
        </div>
      </div>

      <div className="help-content-container">
        <div className="help-header-container">
          <h1 className="help-page-title">How can we help?</h1>
        </div>

        <div className="help-main-sections">
          <div className="help-section-box" onClick={() => openModal('Guides')}>
            <i className="fa-solid fa-book help-section-icon"></i>
            <p>Learning Path</p>
          </div>
          <div className="help-section-box" onClick={() => openModal('Insights')}>
            <i className="fa-solid fa-magnifying-glass-chart help-section-icon"></i>
            <p>Insights</p>
          </div>
          <div
            className="help-section-box"
            onClick={() => openModal('Quiz')}
          >
            <i className="fa-solid fa-graduation-cap help-section-icon"></i>
            <p>Quiz</p>
          </div>
        </div>

        <div className="help-getting-started">
          <ul className="help-getting-started-list">
            <li>
              <button
                className="toggle-button"
                onClick={() => toggleSection('description')}
              >
                General Description
              </button>
              {expandedSection === 'description' && (
                <div className="expanded-content">
                  <p>
                    Welcome to <b>Sofia</b>, your AI-powered tutor for mastering digital marketing. Sofia utilizes advanced AI technology to help you understand key concepts through interactive dialogue, fostering deeper comprehension and critical thinking.
                    <br /><br />
                    With personalized study sessions and realistic oral test simulations, Sofia aims to empower you to excel in your studies. Whether you're looking to deepen your knowledge or engage with new learning methods, Sofia is here to support your journey.
                  </p>
                </div>
              )}
            </li>

            <li>
              <button
                className="toggle-button"
                onClick={() => toggleSection('about')}
              >
                About Soul Machines
              </button>
              {expandedSection === 'about' && (
                <div className="expanded-content">
                  <p>
                    <b>Soul Machines</b> is a New Zealand-based company that
                    humanizes AI by creating lifelike digital avatars capable of
                    natural, empathetic interactions. Using their patented
                    "Biological AI" technology, they develop AI-driven digital
                    humans for industries like education, customer service, and
                    healthcare. These avatars simulate human emotions and
                    behaviors, providing personalized, real-time engagement.
                    Soul Machines' technology is used by global enterprises like
                    Google and Microsoft to enhance customer experiences and
                    virtual training. Committed to ethical AI, Soul Machines
                    ensures their innovations enhance human interactions and
                    accessibility, making AI more relatable and impactful across
                    various sectors.
                  </p>
                  <p>
                    For more information, visit
                    <a
                      style={{
                        textDecoration: 'none',
                        fontWeight: 'bold',
                        cursor: 'pointer',
                        marginLeft: '4px',
                      }}
                      href="https://www.soulmachines.com/about-soul-machines"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Soul Machines
                    </a>
                  </p>
                </div>
              )}
            </li>
            <li>
              <button
                className="toggle-button"
                onClick={() => toggleSection('started')}
              >
                Getting Started
              </button>
              {expandedSection === 'started' && (
                <div className="expanded-content">
                  <p>
                    Once logged in with your university email, you can view all the courses you are enrolled in on the <b>Learning</b> page. After selecting a course, you can click on a specific topic within a module to focus your learning. You can interact with Sofia by either talking or typing, making your learning experience more engaging and personalized.
                  </p>
                </div>
              )}
            </li>
            <li>
              <button
                className="toggle-button"
                onClick={() => toggleSection('enrollment')}
              >
                Course Enrollment
              </button>
              {expandedSection === 'enrollment' && (
                <div className="expanded-content">
                  Students do not have the ability to self-enroll in courses.
                  Instead, instructors are responsible for enrolling students in
                  the relevant courses based on their academic needs. If you
                  require assistance with course enrollment, please contact your
                  course instructor directly for further guidance.
                </div>
              )}
            </li>
            <li>
              <button
                className="toggle-button"
                onClick={() => toggleSection('IOA')}
              >
                Interactive Oral Assessment (IOA) Practice
              </button>
              {expandedSection === 'IOA' && (
                <div className="expanded-content">
                  <p>
                    To enhance your practice experience for the Interactive Oral Assessment (IOA), we provide a convenient switch toggle located at the top right corner of the digital avatar interface. This toggle allows you to seamlessly switch between the <strong>Learning and Quizzes mode</strong> and the <strong>Interactive Oral Assessment mode</strong>.
                  </p>
                  <ul style={{ listStyleType: 'none', paddingLeft: 0, margin: 0 }}>
                    <li style={{ marginBottom: '0.5rem' }}>
                      <img
                        src={mode1}
                        alt="Learning and Quizzes mode"
                        style={{ maxWidth: '220px', height: 'auto', display: 'block' }}
                      />
                      <br />
                      This is the default setting, designed to help you build your knowledge through structured learning and quiz assessments. Engage with the content at your own pace and take quizzes to test your understanding.
                    </li>
                    <li style={{ marginBottom: '0.7rem' }}>
                      <img
                        src={mode2}
                        alt="IOA mode"
                        style={{ maxWidth: '250px', height: 'auto', display: 'block' }}
                      />
                      <br />
                      When you're ready to practice for your IOA, simply toggle the switch to enter this mode. This feature simulates real assessment scenarios, allowing you to practice your speaking and comprehension skills in a supportive environment.
                    </li>
                  </ul>
                  <p>
                    Feel free to toggle between modes to maximize your learning and prepare effectively for your assessments!
                  </p>
                </div>
              )}
            </li>
          </ul>
        </div>

        {modalContent === 'Quiz' && (
          <HelpQuizModal closeModal={closeModal} />
        )}
        {modalContent === 'Insights' && <HelpSupportModal closeModal={closeModal} />}
        {modalContent === 'Guides' && (
          <HelpGuideModal closeModal={closeModal} />
        )}
      </div>

      <ProfileModal
        isOpen={isProfileModalOpen}
        onClose={handleCloseProfileModal}
      />
    </div>
  );
};

export default Help;
